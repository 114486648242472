<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2021-12-27 17:13:42
 * @LastEditTime: 2024-01-05 15:59:07
 * @LastEditors: Ray
-->
<template>
  <div id="RoleMangerForm" class="ms-index" v-cloak>
    <el-header class="ms-header ms-tr" height="50px">
      <el-button
        type="primary"
        icon="iconfont icon-baocun"
        size="mini"
        @click="save()"
        :loading="saveDisabled"
        >保存</el-button
      >
      <el-button
        size="mini"
        icon="iconfont icon-fanhui"
        plain
        onclick="javascript:history.go(-1)"
        >返回</el-button
      >
    </el-header>
    <el-main class="ms-container" style="overflow: hidden">
      <el-scrollbar class="ms-scrollbar" style="height: 100%;">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="100px"
          size="mini"
        >
          <el-form-item label="角色名称" prop="roleName">
            <el-input
              v-model="form.roleName"
              :disabled="false"
              :style="{ width: '20%' }"
              :clearable="true"
              placeholder="请输入角色名称"
            >
            </el-input>
            <div class="ms-form-tip">角色名称不能为空,且不能重复</div>
          </el-form-item>
          <el-form-item label="数据权限" prop="dataPermission">
            <el-input
              type="textarea"
              v-model="form.dataPermission"
              :disabled="false"
              :style="{ width: '60%' }"
              :clearable="true"
              placeholder="请输入角色数据权限"
            >
            </el-input>
            <div class="ms-form-tip">模版：[{"permissionType": "ojyh:content:type", "permission": "[\"PROD\",\"SCI\",\"KNOWLEDGE_BANK\"]"}]</div>
          </el-form-item>
          <el-form-item class="tree-table">
            <span slot="label">角色菜单</span>
            <el-tabs type="border-card" class="channel-tab">
              <el-tab-pane
                v-for="(item, index) in modelList"
                :label="item.id"
                :value="item.id"
                :key="index"
              >
                <span slot="label"
                  ><el-badge
                    :value="badge[item.channel]"
                    class="item"
                    type="primary"
                  >
                    {{ item.channelName }}
                  </el-badge>
                </span>

                <el-table
                  v-loading="loading"
                  height="calc(100vh - 148px)"
                  border
                  :data="computeModelArray(item.menus)"
                  header-row-class-name="ms-table-head"
                  row-class-name="ms-table-row"
                  row-key="modelId"
                  default-expand-all
                  :tree-props="{
                    children: 'modelChildren',
                    hasChildren: 'hasChildren',
                  }"
                >
                  <template slot="empty">
                    {{ emptyText }}
                  </template>
                  <el-table-column
                    label="模块标题"
                    prop="modelName"
                    width="300"
                  ></el-table-column>
                  <el-table-column label="功能权限">
                    <template slot-scope="scope" class="ms-row">
                      <div>
                        <label class="ms-check">
                          <el-checkbox-group
                            v-model="roleIds"
                            @change="
                              (checked) => handleCheckedIdsChange(checked, item)
                            "
                          >
                            <el-checkbox
                              v-for="model in computeRoleId(
                                scope.row.children,
                                item
                              )"
                              :label="model.modelId"
                              :value="model.modelId"
                              :key="model.modelId"
                              >{{ model.modelName }}</el-checkbox
                            >
                          </el-checkbox-group>
                        </label>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </el-main>
  </div>
</template>

<script>
import {
  getRoleDetail,
  saveRoleAcount,
  queryModelListData,
  updateRoleAcount,
} from '@/api/System/manger.js'
export default {
  name: 'RoleMangerForm',
  props: {},
  data() {
    return {
      saveDisabled: false,
      //表单数据,
      badge: {},
      channelRoleId: {},
      form: {
        channels: [],
        modelIds: [],
        // 角色名称
        roleName: '',
        dataPermission:''
        //角色编号
      },
      modelList: [],
      //菜单集合
      roleIds: [],
      //组菜单
      loading: true,
      emptyText: '',
      rules: {
        // 角色名称
        roleName: [
          {
            required: true,
            message: '角色名称必须填写',
          },
        ],
      },
    }
  },
  watch: {
    badge: {
      handler(val) {
        // console.log('watch',val)
      },
      deep: true,
    },
  },
  created() {
    this.form.id = this.$util.getParameter('id')
    this.list()
  },
  methods: {
    computeRoleId(array, item) {
      var that = this
      if (typeof array != 'undefined') {
        array.forEach((item2) => {
          if (that.channelRoleId[item.channel].indexOf(item2.modelId) == -1) {
            that.channelRoleId[item.channel].push(item2.modelId)
          }
        })
      }
      return array
    },
    computeModelArray(data) {
      let newModelList = []
      data.forEach((item) => {
        if (item.menu) {
          newModelList.push({
            modelName: item.modelName,
            modelChildren: item.children,
            modelId: item.modelId,
          })
        }
      })
      return newModelList
    },
    arraySameArray(arr1, arr2) {
      return arr1.filter((item) => {
        if (arr2.indexOf(item) != -1) {
          return item
        }
      })
    },
    //单元格事件
    handleCheckedIdsChange: function(value, item) {
      var that = this
      // that.roleIds = ''
      that.roleIds = value
      that.badge[item.channel] = that.arraySameArray(
        value,
        that.channelRoleId[item.channel]
      ).length
      that.badge = JSON.parse(JSON.stringify(that.badge))
      that.form.channels = []
      for (let key in that.badge) {
        if (that.badge[key] != 0) {
          that.form.channels.push(key)
        }
      }
    },
    //查询列表
    list(id) {
      var that = this
      queryModelListData(that, {})
        .then(function(data) {
          that.loading = false
          if (data.data.length > 0) {
            that.modelList = data.data
            data.data.forEach((item) => {
              that.badge[item.channel] = 0
              that.channelRoleId[item.channel] = []
            })

            if (that.form.id) {
              that.getRoleDetail(that.form.id)
            }

            that.emptyText = ''
            that.loading = false
          } else {
            that.emptyText = '暂无数据'
          }
        })
        .catch((err) => {
          that.loading = false
        })
    },
    save() {
      var that = this
      that.form.modelIds = that.roleIds
      console.log(that.form)
      if (that.form.modelIds.length > 0) {
        //是否选择了菜单
        this.$refs.form.validate(function(valid) {
          if (valid) {
            that.saveDisabled = true
            var data = JSON.parse(JSON.stringify(that.form))
            console.log(data.dataPermission)
            if (data.dataPermission) {
              try {
                data.dataPermission = JSON.parse(data.dataPermission)
              } catch (error) {
                console.log(error)
                that.saveDisabled = false
                that.$notify({
                  title: '失败',
                  message: '数据权限格式错误，请按照模版填写',
                  type: 'warning',
                })
                return
              }
            } else { 
              data.dataPermission = []
            }

            if (that.form.id) {
              updateRoleAcount(that, data)
                .then(function(res) {
                  console.log('data', res)
                  if (res.data.result) {
                    that.$notify({
                      title: '成功',
                      message: '保存成功',
                      type: 'success',
                    })
                    that.$router.push({ name: 'RoleMangerList' })
                  } else {
                    that.$notify({
                      title: '失败',
                      message: data.msg,
                      type: 'warning',
                    })
                  }
                  that.saveDisabled = false
                })
                .catch((error) => {
                  that.saveDisabled = false
                })
            } else {
              saveRoleAcount(that, data)
                .then(function(res) {
                  console.log('data', res)
                  if (res.data.result) {
                    that.$notify({
                      title: '成功',
                      message: '保存成功',
                      type: 'success',
                    })
                    that.$router.push({ name: 'RoleMangerList' })
                  } else {
                    that.$notify({
                      title: '失败',
                      message: data.msg,
                      type: 'warning',
                    })
                  }
                  that.saveDisabled = false
                })
                .catch((error) => {
                  that.saveDisabled = false
                })
            }
          } else {
            return false
          }
        })
      } else {
        that.$notify({
          title: '提示',
          message: '请选择菜单',
          type: 'warning',
        })
      }
    },
    //获取当前角色详情
    getRoleDetail(id) {
      var that = this
      that.loading = true
      getRoleDetail(that, id)
        .then(function(data) {
          if (data.data.roleName) {
            console.log(data.data)
            that.form.roleName = data.data.roleName
            console.log(data.data.dataPermission)
            if (data.data.dataPermission.length > 0) {
              that.form.dataPermission = JSON.stringify(data.data.dataPermission)
            } else { 
              that.form.dataPermission = ''
            }
            that.roleIds = data.data.modelIds.map(Number)
            that.modelList.forEach((item) => {
              that.badge[item.channel] = that.arraySameArray(
                that.roleIds,
                that.channelRoleId[item.channel]
              ).length
            })
            that.form.channels = []
            for (let key in that.badge) {
              if (that.badge[key] != 0) {
                that.form.channels.push(key)
              }
            }
          }
        })
        .catch((err) => {
          that.loading = false
        })
    },
  },
}
</script>
<style>
.channel-tab .el-tabs__nav-scroll {
  padding-top: 10px;
}
</style>
<!-- <style scoped></style> -->
