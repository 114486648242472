<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2021-12-30 16:13:16
 * @LastEditTime: 2022-07-06 15:37:37
 * @LastEditors: Ray
-->
<template>
  <div id="AdminManger">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AdminManger',
}
</script>
