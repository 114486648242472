import axios from 'axios'
import xss from 'xss'
import store from '../vuex/store'
import ErrorCode from './errorCode'
var bcrypt = require('bcryptjs')

// 防止同一时间多次请求同一接口
axios.defaults.timeout = 30000
const pending = []
const CancelToken = axios.CancelToken
const removePending = (config) => {
  for (const p in pending) {
    if (pending[p].u === config.url + '&' + config.method) {
      pending[p].f()
      pending.splice(p, 1)
    }
  }
}
// 在main.js设置全局的请求次数，请求的间隙
// axios.defaults.retry = 0
// axios.defaults.retryDelay = 0
// axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
//   var config = err.config
//   if (!config || !config.retry) return Promise.reject(err)
//   config.__retryCount = config.__retryCount || 0
//   if (config.__retryCount >= config.retry) {
//     return Promise.reject(err)
//   }
//   config.__retryCount += 1
//   var backoff = new Promise(function(resolve) {
//     setTimeout(function() {
//       resolve()
//     }, config.retryDelay || 1)
//   })
//   return backoff.then(function() {
//     return axios(config)
//   })
// })
// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    // console.log('config', config.headers.Authorization)
    if (!config.headers.Authorization) {
      removePending(config) // 在一个ajax发送前执行一下取消操作
      config.cancelToken = new CancelToken((c) => {
        pending.push({ u: config.url + '&' + config.method, f: c })
        store.commit('pushToken', { cancelToken: c })
      })
    }
    // removePending(config) // 在一个ajax发送前执行一下取消操作
    // config.cancelToken = new CancelToken((c) => {
    //   pending.push({ u: config.url + '&' + config.method, f: c })
    //   store.commit('pushToken', { cancelToken: c })
    // })
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
let tokenErrorStatus = ''
const resTokenErrorControl = (status) => {
  tokenErrorStatus = status
}
axios.interceptors.response.use(
  (response) => {
    if (response.data.data) {
      response.data.data = response.data.data
    }
    return response
  },
  (error) => {
    let status = ''
    if (error.request) {
      status = error.request
    } else if (error.response) {
      status = error.response
    }
    if (status) {
      if (status.status) {
        if (status.status == '401') {
          if (!tokenErrorStatus) {
            resTokenErrorControl(true)
            return Promise.reject(error)
          } else {
            let data = {
              status: '00000',
            }
            return Promise.resolve(data)
          }
        } else {
          return Promise.reject(error)
        }
      }
    }
  }
)

export default {
  baseUrl: process.env.VUE_APP_URL,
  signUrl: process.env.VUE_APP_SIGN_URL,
  clientId: process.env.VUE_APP_CLIENT_ID,
  grantType: process.env.VUE_APP_GRANT_TYPE,
  requestMode: process.env.VUE_APP_REQUEST_MODE,
  // skipRegistration: process.env.VUE_APP_SKIP_REGISTRATION,//默认不传
  redirectUrl: process.env.VUE_APP_REDIRECT_URL,
  responseType: process.env.VUE_APP_RESPONSE_TYPEL,
  state: process.env.VUE_APP_STATE,
  userPermission: [],
  editorConfig: {
    imageScaleEnabled: true,
    autoHeightEnabled: true,
    autoFloatEnabled: false,
    scaleEnabled: true,
    compressSide: 0,
    maxImageSideLength: 1000,
    maximumWords: 20000,
    initialFrameWidth: '100%',
    initialFrameHeight: 400,
    fullscreen: false,
    toolbars: [
      [
        'fullscreen',
        'source',
        '|',
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        'underline',
        'fontborder',
        'strikethrough',
        'superscript',
        'subscript',
        '|',
        'forecolor',
        'backcolor',
        'insertorderedlist',
        'insertunorderedlist',
        'cleardoc',
        '|',
        'rowspacingtop',
        'rowspacingbottom',
        'lineheight',
        '|',
        'customstyle',
        'paragraph',
        'fontfamily',
        'fontsize',
        '|',
        'indent',
        '|',
        'justifyleft',
        'justifycenter',
        'justifyright',
        'justifyjustify',
        '|',
        'touppercase',
        'tolowercase',
        '|',
        'link',
        'unlink',
        '|',
        'imagenone',
        'imageleft',
        'imageright',
        'imagecenter',
        '|',
        'simpleupload',
        'insertimage',
        // 'insertvideo',
        '|',
        'inserttable',
        'deletetable',
        'insertparagraphbeforetable',
        'insertrow',
        'deleterow',
        'insertcol',
        'deletecol',
        'mergecells',
        'mergeright',
        'mergedown',
        'splittocells',
        'splittorows',
        'splittocols',
        '|',
        'searchreplace',
        'customButton',
      ],
    ],
    labelMap: {
      searchreplace: '查找替换',
    },
    serverUrl:
      process.env.VUE_APP_URL +
      '/static/plugins/ueditor/1.4.3.3/jsp/editor?jsonConfig=%7BvideoUrlPrefix:%27%27,fileManagerListPath:%27%27,imageMaxSize:204800000,videoMaxSize:204800000,fileMaxSize:204800000,fileUrlPrefix:%27%27,imageUrlPrefix:%27%27,imagePathFormat:%27/upload/1/cms/content/editor/%7Btime%7D%27,filePathFormat:%27/upload/1/cms/content/editor/%7Btime%7D%27,videoPathFormat:%27/upload/1/cms/content/editor/%7Btime%7D%27%7D',
    // UEDITOR_HOME_URL:
    //   'https://uatocmsapi.organonchina.com.cn/static/plugins/ueditor/1.4.3.3/hqc.js',
    UEDITOR_HOME_URL: process.env.VUE_APP_S3_URL+'/OCMS/editor/digital-ueditor/1.4.3.3/',
    // UEDITOR_HOME_URL: process.env.VUE_APP_S3_URL +'/OCMS/editor/digital-ueditor/1.4.3.3/',
    // UEDITOR_HOME_URL: '/static/digital-ueditor/1.4.3.3/',
  },
  olxyEditorConfig: {
    imageScaleEnabled: true,
    autoHeightEnabled: true,
    autoFloatEnabled: false,
    scaleEnabled: true,
    compressSide: 0,
    maxImageSideLength: 1000,
    maximumWords: 20000,
    initialFrameWidth: '100%',
    initialFrameHeight: 400,
    fullscreen: false,
    toolbars: [
      [
        'fullscreen',
        'source',
        '|',
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        'underline',
        'fontborder',
        'strikethrough',
        'superscript',
        'subscript',
        '|',
        'forecolor',
        'backcolor',
        'insertorderedlist',
        'insertunorderedlist',
        'cleardoc',
        '|',
        'rowspacingtop',
        'rowspacingbottom',
        'lineheight',
        '|',
        'customstyle',
        'paragraph',
        'fontfamily',
        'fontsize',
        '|',
        'indent',
        '|',
        'justifyleft',
        'justifycenter',
        'justifyright',
        'justifyjustify',
        '|',
        'touppercase',
        'tolowercase',
        '|',
        'link',
        'unlink',
        '|',
        'imagenone',
        'imageleft',
        'imageright',
        'imagecenter',
        '|',
        'simpleupload',
        'insertimage',
        'insertvideo',
        '|',
        'inserttable',
        'deletetable',
        'insertparagraphbeforetable',
        'insertrow',
        'deleterow',
        'insertcol',
        'deletecol',
        'mergecells',
        'mergeright',
        'mergedown',
        'splittocells',
        'splittorows',
        'splittocols',
        '|',
        'searchreplace',
        'customButton',
      ],
    ],
    labelMap: {
      searchreplace: '查找替换',
    },
    serverUrl:
      process.env.VUE_APP_URL +
      '/static/plugins/ueditor/1.4.3.3/jsp/editor?jsonConfig=%7BvideoUrlPrefix:%27%27,fileManagerListPath:%27%27,imageMaxSize:204800000,videoMaxSize:204800000,fileMaxSize:204800000,fileUrlPrefix:%27%27,imageUrlPrefix:%27%27,imagePathFormat:%27/upload/1/cms/content/editor/%7Btime%7D%27,filePathFormat:%27/upload/1/cms/content/editor/%7Btime%7D%27,videoPathFormat:%27/upload/1/cms/content/editor/%7Btime%7D%27%7D',
    // UEDITOR_HOME_URL:
    //   'https://uatocmsapi.organonchina.com.cn/static/plugins/ueditor/1.4.3.3/hqc.js',
    UEDITOR_HOME_URL: process.env.VUE_APP_S3_URL+'/OCMS/editor/digital-ueditor/1.4.3.3/',
    // UEDITOR_HOME_URL: process.env.VUE_APP_S3_URL +'/OCMS/editor/digital-ueditor/1.4.3.3/',
    // UEDITOR_HOME_URL: '/static/digital-ueditor/1.4.3.3/',
  },
  comparePermisson(modelUrl) {
    var modeAllData = JSON.parse(localStorage.getItem('permissions'))
    var computeResult = false
    if (modeAllData.indexOf(modelUrl) != -1) {
      computeResult = true
    }
    return computeResult
  },
  //操作成功提示公用方法
  success(obj, msg, url) {
    obj.$notify({
      message: msg,
      type: 'success',
      offset: 38,
      duration: '2000',
      onClose: function() {
        if (url !== '' && url != null) {
          obj.$router.push(url)
        }
      },
    })
  },
  //操作失败提示公用方法
  error(obj, msg, url) {
    obj.$notify.error({
      message: msg,
      title: '错误',
      type: 'error',
      offset: 38,
      duration: 0,
      onClose: function() {
        if (url !== '' && url != null) {
          obj.$router.push(url)
        }
      },
    })
  },
  httpPostDaochuDataWithToken(self, url, data) {
    const _this = this
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + url, this.postHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
            // Authorization: _this.getToken(self),
          },
          responseType: 'blob',
        })
        .then((res) => {
          // res.status == 200
          // if (res.data.status == 1) {
          if (res.status == 200) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          // reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpGetDownWithToken(self, url, data) {
    const _this = this
    return new Promise((resolve, reject) => {
      axios
        .get(this.baseUrl + url + '?' + this.getHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
          responseType: 'blob',
        })
        .then((res) => {
          if (res.status == 200) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          // reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpGetWithToken(self, url, data) {
    const _this = this
    return new Promise((resolve, reject) => {
      axios
        .get(this.baseUrl + url + '?' + this.getHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          // res.status == 200
          // if (res.data.status == 1) {
          if (res.status == 200) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          // reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpDeleteWithToken(self, url, data) {
    const _this = this
    return new Promise((resolve, reject) => {
      axios
        .delete(this.baseUrl + url, {
          data: data,
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
          reject(error)
        })
    })
  },
  httpDeletePostWithToken(self, url, data) {
    const _this = this
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + url, data, {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpPostMedicalTag(self, url, data) {
    const _this = this
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + url, data, {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code && res.data.code != 200) {
              let error = {}
              error.response = res
              this.errorMessage(error, self)
              reject(res.data.message)
            } else {
              resolve(res)
            }
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpPostWithToken(self, url, data) {
    const _this = this
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + url, this.postHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code && res.data.code != 200) {
              let error = {}
              error.response = res
              this.errorMessage(error, self)
              reject(res.data.message)
            } else {
              resolve(res)
            }
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpPatchWithToken (self, url, data) {
    const _this = this
    console.log('bbbb')
    return new Promise((resolve, reject) => {
      axios
        .patch(this.baseUrl + url, this.postHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code && res.data.code != 200) {
              let error = {}
              error.response = res
              this.errorMessage(error, self)
              reject(res.data.message)
            } else {
              resolve(res)
            }
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpPutWithToken(self, url, data) {
    const _this = this
    return new Promise((resolve, reject) => {
      axios
        .put(this.baseUrl + url, this.postHttpData(data), {
          headers: {
            Authorization: 'Bearer ' + _this.getToken(self),
          },
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code && res.data.code != 200) {
              let error = {}
              error.response = res
              this.errorMessage(error, self)
              reject(res.data.message)
            } else {
              resolve(res)
            }
          }
        })
        .catch((error) => {
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
          reject(error)
        })
    })
  },
  httpGet(self, url, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(this.baseUrl + url + '?' + this.getHttpData(data))
        .then((res) => {
          if (res.status == 200) {
            resolve(res.data)
          } else if (res.status == 0) {
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res)
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpPost(self, url, data) {
    const _this = this
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + url, this.postHttpData(data))
        .then((res) => {
          if (res.status == 200) {
            resolve(res.data)
          } else {
            let error = {}
            error.response = res
            this.errorMessage(error, self)
            reject(res.data.message)
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  httpFormPost(self, url, data) {
    const _this = this
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + url, this.getHttpData(data), {
          headers: {
            // Authorization: _this.getToken(self),
            Authorization: 'Bearer ' + _this.getToken(self),
            // 'Content-Type': 'multipart/form-data'//含有附件的表单提交的Content-Type
            'Content-Type': 'application/x-www-form-urlencoded', //普通表单提交的Content-Type
          },
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code && res.data.code != 200) {
              let error = {}
              error.response = res
              this.errorMessage(error, self)
              reject(res.data.message)
            } else {
              resolve(res.data)
            }
          }
        })
        .catch((error) => {
          reject(error)
          if (error.toString().indexOf('Network Error') !== -1) {
            return this.error(self, '当前网络状态不稳定，请重试', '')
          }
          this.errorMessage(error, self)
        })
    })
  },
  postHttpData: function(data) {
    // let paramsData = JSON.parse(xss(JSON.stringify(data)))
    let paramsData = data
    var temp = {}
    for (var i in paramsData) {
      if (paramsData[i] !== null && paramsData[i] !== 'null') {
        temp[i] = paramsData[i]
      }
    }
    return temp
  },
  getHttpData: function(data) {
    let paramsData = JSON.parse(JSON.stringify(data))
    // let paramsData = data
    var temp = ''
    for (var i in paramsData) {
      if (paramsData[i] != null && paramsData[i] != 'null') {
        if (typeof paramsData[i] == 'string') {
          if (paramsData[i].indexOf('&') != -1) {
            paramsData[i] = encodeURIComponent(paramsData[i])
          }
        }

        temp = temp + '&' + i + '=' + paramsData[i]
      }
    }
    temp = temp.substr(1, temp.length - 1)
    return temp
  },
  getPermisson: function() {
    return JSON.parse(localStorage.getItem('userPermission'))
  },
  setPermisson: function(permission) {
    localStorage.setItem('userPermission', JSON.stringify(permission))
  },
  clearPermisson: function() {
    localStorage.removeItem('userPermission')
  },
  getChannel(self) {
    if (self.$cookie) {
      return self.$cookie.get('channel')
    } else {
      return self.get('channel')
    }
  },
  getShaixuan: function(name) {
    return JSON.parse(localStorage.getItem(name))
  },
  setShaixuan: function(name, status) {
    localStorage.setItem(name, JSON.stringify(status))
  },
  clearShaixuan: function(name) {
    localStorage.removeItem(name)
  },
  toStringArray(data, dataList) {
    const dataArray = []
    const nameList = []
    for (const attr in data) {
      const id = parseInt(data[attr])
      if (!isNaN(id)) {
        dataArray.push(id)
      }
    }
    for (const attr in dataArray) {
      for (const att in dataList) {
        if (dataArray[attr] == dataList[att].id) {
          nameList.push(dataList[att].name)
        }
      }
    }
    return nameList
  },
  toNumArray(data) {
    const dataArray = []
    for (const attr in data) {
      const id = parseInt(data[attr])
      if (!isNaN(id)) {
        dataArray.push(id)
      }
    }
    return dataArray
  },
  setChannel (self,code) { 
    window.document.cookie =
      'channel=' + escape(code) + ';expires=' + this.getCookieExpires(self) + ';path=/'
  },
  setCookieExpires (time) { 
    window.document.cookie =
      'expiresTime=' + escape(time) + ';expires=' + time + ';path=/'
  },
  getCookieExpires(self) {
    if (self.$cookie) {
      return self.$cookie.get('expiresTime')
    } else {
      return self.get('expiresTime')
    }
  },
  setToken(name, value, expiresTime) {
    var exp = new Date()
    exp.setTime(exp.getTime() + expiresTime * 1000)
    this.setCookieExpires(exp.toGMTString())
    window.document.cookie =
      name + '=' + escape(value) + ';expires=' + exp.toGMTString() + ';path=/'
  },
  getToken(self) {
    if (self.$cookie) {
      return self.$cookie.get('token')
    } else {
      return self.get('token')
    }
  },
  deleToken(name) {
    this.setToken(name, '', -1)
  },
  getIsid(self) {
    return self.$cookie.get('isid')
  },
  // 时间转换
  toDate(date) {
    if (date == null) {
      return null
    } else {
      var date2 = new Date(date)
      var toTwo = function(time) {
        if (time < 10) {
          time = '0' + time
          return time
        }
        return time
      }
      return (
        date2.getFullYear() +
        '-' +
        toTwo(date2.getMonth() + 1) +
        '-' +
        toTwo(date2.getDate()) +
        ' ' +
        toTwo(date2.getHours()) +
        ':' +
        toTwo(date2.getMinutes()) +
        ':' +
        toTwo(date2.getSeconds())
      )
    }
  },
  //errorMessage
  errorMessage(error, self) {
    for (let i in ErrorCode) {
      if (error.response) {
        if (error.response.status == i) {
          //匹配status
          console.log('i', i)
          if (i == 500) {
            this.error(self, '系统错误', '')
          }
          if (i == 404) {
            self.$router.push({ path: '/error' })
          }
          if (i == 400) {
            this.error(self, error.response.data.msg, '')
          }
          if (i == 200) {
            this.error(self, error.response.data.msg, '')
          }
          if (i == 401) {
            this.error(self, error.response.data.msg, '')
            self.$cookie.delete('token')
            self.$cookie.delete('channel')
            localStorage.setItem('markList', [])
            localStorage.setItem('editableTabs', '')
            self.$router.push({ path: '/login' })
          }
        }
      }
    }
  },
}
