/*
 * @Description:
 * @Author: Ray
 * @Date: 2021-12-20 15:39:47
 * @LastEditTime: 2024-06-19 16:04:46
 * @LastEditors: Ray
 */
/**
 * 通用工具方法
 */
/**
 * 地址栏获取参数
 * @param name 参数名称
 * @return {*}
 */
function getParameter(name) {
  try {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    var r = window.location.search.substr(1).match(reg)
    if (r != null) {
      return decodeURI(r[2])
    }
    return null
  } catch (e) {
    console.log(e.message)
  }
}

/**
 * 列表数据转化为树形结构的列表
 * @param source 数据源list
 * @param id 编号
 * @param parentId 父级编号
 * @param children 树形子集变量
 * @returns {*}
 * 支持父级编号为 0或null
 * 原始数据[{id:1,titile:"标题",pid:0},{id:2,titile:"标题",pid:1}]
 * 转化树形数据：[{id:1,titile:"标题",pid:0,children:[{id:2,titile:"标题",pid:1}]}]
 */
function treeData(source, id, parentId, children) {
  var cloneData = JSON.parse(JSON.stringify(source))
  return cloneData.filter(function(father) {
    var branchArr = cloneData.filter(function(child) {
      return father[id] == child[parentId]
    })
    branchArr.length > 0 ? (father[children] = branchArr) : ''
    return (
      !father[parentId] || father[parentId] == '0' || father[parentId] == null
    )
  })
}
//验证是否为子集
function childValidate(sourceList, id, parentId, key, parentKey) {
  var data = sourceList.find(function(x) {
    return x[key] == parentId
  })
  if (data && data[parentKey] != '0' && data[parentKey]) {
    if (id == data[parentKey]) {
      return false
    }
    return childValidate(sourceList, id, data[parentKey], key, parentKey)
  }
  return true
}

//日期工具
var date = {
  //格式化时间
  fmt: function(de, fmt) {
    var date = new Date(typeof de == 'string' ? de.replace(/-/g, '/') : de)
    if (!fmt) {
      fmt = 'yyyy-MM-dd'
    }
    var o = {
      'M+': date.getMonth() + 1, //月份
      'd+': date.getDate(), //日
      'h+': date.getHours(), //小时
      'm+': date.getMinutes(), //分
      's+': date.getSeconds(), //秒
      'q+': Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds(), //毫秒
    }
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    for (var k in o)
      if (new RegExp('(' + k + ')').test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        )
    return fmt
  }
}


var convert = {
  byte: function(bytes) {
    if (isNaN(bytes)) {
      return ''
    }
    var symbols = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    var exp = Math.floor(Math.log(bytes) / Math.log(2))
    if (exp < 1) {
      exp = 0
    }
    var i = Math.floor(exp / 10)
    bytes = bytes / Math.pow(2, 10 * i)

    if (bytes.toString().length > bytes.toFixed(2).toString().length) {
      bytes = bytes.toFixed(2)
    }
    return bytes + ' ' + symbols[i]
  },

  //根据经纬度计算记录
  distance: function(lon1, lat1, lon2, lat2) {
    var DEF_PI = 3.14159265359 // PI
    var DEF_2PI = 6.28318530712 // 2*PI
    var DEF_PI180 = 0.01745329252 // PI/180.0
    var DEF_R = 6370693.5 // radius of earth

    var ew1, ns1, ew2, ns2
    var dx, dy, dew
    var distance
    // 角度转换为弧度
    ew1 = lon1 * DEF_PI180
    ns1 = lat1 * DEF_PI180
    ew2 = lon2 * DEF_PI180
    ns2 = lat2 * DEF_PI180
    // 经度差
    dew = ew1 - ew2
    // 若跨东经和西经180 度，进行调整
    if (dew > DEF_PI) dew = DEF_2PI - dew
    else if (dew < -DEF_PI) dew = DEF_2PI + dew
    dx = DEF_R * Math.cos(ns1) * dew // 东西方向长度(在纬度圈上的投影长度)
    dy = DEF_R * (ns1 - ns2) // 南北方向长度(在经度圈上的投影长度)
    // 勾股定理求斜边长
    distance = Math.sqrt(dx * dx + dy * dy).toFixed(0)
    return distance
  },
}

/**
 * window.sessionStorage方法封装
 * @type {{set: set, get: (function(*=): string), remove: remove}}
 */
var store = {
  set: function(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  },
  get: function(key) {
    return  JSON.parse(window.sessionStorage.getItem(key))
  },
  remove: function(key) {
    window.sessionStorage.removeItem(key)
  },
}



function timestampToTime  (timestamp, model = 'YMD', connect = '-') {
  if (timestamp) {
    var date = new Date(timestamp)
    var Y = date.getFullYear() + connect
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + connect
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
    var h = `${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`}:`
    var m = `${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}:`
    var s = `${date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`}`
    if (model === 'YMD') {
      return Y + M + D
    } else if (model === 'HMS') {
      return Y + M + D + h + m + s
    } else if (model === 'M') {
      return M.replace(connect, '')
    } else if (model === 'HM') {
      return h + m.replace(':', '')
    }
  } else {
    return '无'
  }
}

export default {
  getParameter: getParameter,
  treeData: treeData,
  childValidate: childValidate,
  date: date,
  convert: convert,
  store: store,
  timestampToTime:timestampToTime
}
