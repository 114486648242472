/*
 * @Description:
 * @Author: Ray
 * @Date: 2021-12-17 15:06:44
 * @LastEditTime: 2024-06-19 16:05:03
 * @LastEditors: Ray
 */
import Vue from 'vue'

import Router from 'vue-router'
import Layout from '@/components/Layout/Layout'
import Login from '@/components/Login/Login'
import Main from '@/components/Main/Main'
import Error from '@/components/Error/Error'

import AdminManger from '@/components/System/AdminManger/AdminMangerIndex'
import AdminMangerList from '@/components/System/AdminManger/AdminMangerList'
import MangerLog from '@/components/System/MangerLog/MangerLogIndex'
import MangerLogList from '@/components/System/MangerLog/MangerLogList'
import MangerLogDetail from '@/components/System/MangerLog/MangerLogDetail'
import RoleManger from '@/components/System/RoleManger/RoleMangerIndex'
import RoleMangerList from '@/components/System/RoleManger/RoleMangerList'
import RoleMangerForm from '@/components/System/RoleManger/RoleMangerForm'


/*   */
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(Router)

export default new Router({
  base:'/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Layout,
      redirect: '/Management',
      meta: [{ name: 'Management', pathName: '/' }, { needLogin: true }],
    },
    {
      path: '/Login',
      name: 'Login',
      component: Login,
      meta: [{ name: 'Login', pathName: '/' }, { needLogin: false }],
    },
    {
      path: '/Management',
      name: 'Management',
      component: Layout,
      meta: [{ name: 'Main', pathName: 'Management' }, { needLogin: true }],
      children: [
        // 系统设置
        {
          path: 'Main',
          component: Main,
          name: 'Main',
          meta: [{ name: '工作台', pathName: 'Main' }, { needLogin: true }],
        },
        // System
        {
          path: 'AdminManger',
          component: AdminManger,
          redirect: '/Management/AdminManger/AdminMangerList',
          name: 'AdminManger',
          meta: [
            { name: 'AdminManger', pathName: 'AdminManger' },
            { needLogin: true },
          ],
          children: [
            {
              path: 'AdminMangerList',
              component: AdminMangerList,
              name: 'AdminMangerList',
              meta: [
                {
                  name: 'AdminMangerList',
                  pathName: 'AdminMangerList',
                  parentName: 'AdminManger',
                },
                { needLogin: true },
              ],
            },
          ],
        },
        {
          path: 'MangerLog',
          component: MangerLog,
          redirect: '/Management/MangerLog/MangerLogList',
          name: 'MangerLog',
          meta: [
            { name: 'MangerLog', pathName: 'MangerLog' },
            { needLogin: true },
          ],
          children: [
            {
              path: 'MangerLogList',
              component: MangerLogList,
              name: 'MangerLogList',
              meta: [
                {
                  name: 'MangerLogList',
                  pathName: 'MangerLogList',
                  parentName: 'MangerLog',
                },
                { needLogin: true },
              ],
            },
            {
              path: 'MangerLogDetail',
              component: MangerLogDetail,
              name: 'MangerLogDetail',
              meta: [
                {
                  name: 'MangerLogDetail',
                  pathName: 'MangerLogDetail',
                  parentName: 'MangerLog',
                },
                { needLogin: true },
              ],
            },
          ],
        },
        {
          path: 'RoleManger',
          component: RoleManger,
          redirect: '/Management/RoleManger/RoleMangerList',
          name: 'RoleManger',
          meta: [
            { name: 'RoleManger', pathName: 'RoleManger' },
            { needLogin: true },
          ],
          children: [
            {
              path: 'RoleMangerList',
              component: RoleMangerList,
              name: 'RoleMangerList',
              meta: [
                {
                  name: 'RoleMangerList',
                  pathName: 'RoleMangerList',
                  parentName: 'RoleManger',
                },
                { needLogin: true },
              ],
            },
            {
              path: 'RoleMangerForm',
              component: RoleMangerForm,
              name: 'RoleMangerForm',
              meta: [
                {
                  name: 'RoleMangerForm',
                  pathName: 'RoleMangerForm',
                  parentName: 'RoleManger',
                },
                { needLogin: true },
              ],
            },
          ],
        }
      ],
    },
    {
      path: '*',
      name: 'Error',
      component: Error,
      meta: [{ name: '404', pathName: '/Error' }, { needLogin: false }],
    },
  ],
})
