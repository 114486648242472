<template>
  <div class="index" :class="theme">
    <el-container>
      <!--左侧菜单-->
      <el-aside class="ms-admin-menu-aside" v-cloak>
        <el-scrollbar style="height:100%">
          <el-menu
            :default-active="asideMenuActive.toString()"
            class="el-menu-vertical-demo"
            text-color="rgba(255,255,255,1)"
            active-text-color="rgba(255,255,255,1)"
            :collapse="collapseMenu"
            :unique-opened="true"
            :default-openeds="defaultOpenedsArray"
            ref="menu"
            @open="asideMenuOpen"
          >
            <div
              class="ms-admin-logo"
              :style="{
                display: 'flex',
                'padding-left': collapseMenu ? '5px' : '22px',
              }"
            >
              <div
                class="layout-class-1"
                style="font-size: 18px"
                v-show="!collapseMenu"
              >
                Organon&nbsp;
              </div>
              <div class="layout-class-1" v-show="!collapseMenu">
                <!-- <div class="layout-class-2">&nbsp;v</div> -->
                {{ channelName }}
              </div>
            </div>
            <el-submenu
              :popper-class="propClass"
              :index="menu.modelId + ''"
              :data-index="menu.modelId + ''"
              v-for="(menu, i) in asideMenuList"
              v-show="menu.checked"
              :key="i"
            >
              <template slot="title">
                <i class="ms-admin-icon iconfont" :class="menu.modelIcon"></i>
                <span>{{ menu.modelName }}</span>
              </template>
              <!-- 子菜单 -->
              <el-menu-item
                :index="sub.modelId + ''"
                :data-index="sub.modelId"
                v-for="sub in getSubMenu(menu.modelId)"
                :key="sub.modelId"
                v-text="sub.modelName"
                v-show="sub.checked"
                @click.self="open(sub)"
              ></el-menu-item>
            </el-submenu>
            <!-- 收缩按钮 -->
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <!-- 右侧内容 -->
      <el-container class="ms-admin-container" v-cloak>
        <!--头部-->
        <el-header class="ms-admin-header" v-cloak>
          <!--展示合并菜单-->
          <div
            class="ms-admin-header-menu-shrink"
            @click="collapseMenu = !collapseMenu"
          >
            <i
              class="iconfont"
              :class="collapseMenu ? 'icon-liebiaoxiangyou' : 'icon-shousuo'"
            ></i>
          </div>
          <!--头部menu-->
          <!-- <el-menu
            menu-trigger="hover"
            class="ms-admin-header-menu"
            :default-active="headMenuActive"
            mode="horizontal"
          >
            <el-submenu index="" style="height: 100%;">
              <template slot="title">
                <i
                  class="iconfont icon-gezi"
                  style="font-size: 17px;margin-right:8PX"
                ></i>
                <span>功能大全</span>
              </template>
              <div class="ms-admin-header-menu-all">
                <li
                  class="ms-admin-header-menu-all-item"
                  v-for="(item, index) of parentMenuList"
                  :key="index"
                  @click="openMenu(item, index)"
                >
                  <i
                    class="iconfont"
                    :class="item.modelIcon"
                    style="padding-right: 4px"
                  ></i>
                  <div style="width:80px">{{ item.modelName }}</div>
                  <div style="float: right;width: 18px;">
                    <template>
                      <i
                        v-if="
                          markList.find(function(x) {
                            return x.title == item.modelName
                          }) != undefined
                        "
                        @click="cancelMarkMenu(item.modelName)"
                        class="el-icon-star-on"
                      ></i>
                      <i
                        v-else
                        class="el-icon-star-off "
                        @click="markMenu(item.modelName, item.modelIcon)"
                      ></i>
                    </template>
                  </div>
                </li>
              </div>
            </el-submenu>
          </el-menu> -->
          <!--头部右侧-->
          <div class="ms-admin-header-right">
            <el-menu
              menu-trigger="hover"
              class="ms-admin-header-menu"
              mode="horizontal"
            >
              <el-submenu
                index=""
                style="height: 100%;"
                popper-class="ms-admin-header-menu-item"
              >
                <template slot="title">
                  <i class="iconfont icon-pifu" style="font-size: 18px"></i>
                </template>
                <el-menu-item
                  @click="handleCommand('ms-theme-dark')"
                  style="display: flex;align-items: center"
                >
                  <div
                    style="height: 18px;width: 18px;background-color: rgba(56,58,63,1);margin-right: 7px;border-radius: 2px;"
                  ></div>
                  <span>深色系</span>
                </el-menu-item>
                <el-menu-item
                  @click="handleCommand('ms-theme-light')"
                  style="display: flex;align-items: center"
                >
                  <div
                    style="height: 18px;width: 18px;background-color: rgba(64,158,255,1);margin-right: 7px;border-radius: 2px;"
                  ></div>
                  <span>浅色系</span>
                </el-menu-item>
              </el-submenu>
            </el-menu>
            <!-- 语言切换-->
            <!-- <el-dropdown @command="handleLanguageClick">
              <span class="el-dropdown-link">
                {{ locale.text
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in localeCommands"
                  :key="index"
                  :command="item"
                  >{{ item.text }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown> -->

            <el-menu
              menu-trigger="hover"
              class="ms-admin-header-menu "
              mode="horizontal"
            >
              <el-submenu index="" popper-class="ms-admin-header-menu-item">
                <template slot="title">
                  <span
                    class="ms-admin-people-head"
                    v-text="peopleInfo.isid && peopleInfo.isid.substr(0, 2)"
                  ></span>
                  <span v-text="peopleInfo.isid"></span>
                </template>
                <el-menu-item
                  style="display: flex;align-items: center"
                  @click.native="selectChannel()"
                >
                  <!-- <i class="el-icon-switch-button"></i> -->
                  <!-- <el-avatar size="small" :src="item.img"></el-avatar> -->
                  <span slot="title" style="padding-left: 10px;"
                    >切换客户端</span
                  >
                </el-menu-item>
                <!-- <el-menu-item
                  v-for="(item, index) in channelList"
                  :key="index"
                  style="display: flex;align-items: center"
                  @click.native="selectChannel(item)"
                >
                  <el-avatar size="small" :src="item.img"></el-avatar>
                  <span slot="title" style="padding-left: 10px;">{{
                    item.channelName
                  }}</span>
                </el-menu-item> -->
                <el-menu-item
                  style="display: flex;align-items: center"
                  @click.native="openModal"
                >
                  <i class="el-icon-switch-button"></i>
                  <span slot="title">退出</span>
                </el-menu-item>
              </el-submenu>
            </el-menu>
            <!--mstore按钮-->
            <!-- <ms-store ref="storec" client="${client}"></ms-store> -->
          </div>
        </el-header>
        <!--内容-->
        <el-main
          class="ms-admin-main"
          style="background-color: rgba(238, 238, 238, 1);"
        >
          <!--选项卡-->
          <el-tabs
            class="ms-admin-tabs"
            v-model="currentTab"
            type="card"
            closable
            @tab-remove="closeTab"
            @tab-click="tabClick"
          >
            <el-tab-pane
              v-for="item in editableTabs"
              :key="item.id"
              :label="item.modelName"
              :name="item.modelName"
              :data-id="item.id"
              :data-modelId="item.modelId"
              :data-routeName="item.routeName"
              :data-url="item.url"
            >
            </el-tab-pane>
          </el-tabs>
          <keep-alive>
            <router-view />
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {
  queryMenuListData,
  queryManagerData,
  getChannelList,
} from '@/api/layout.js'
export default {
  name: 'Layout',
  data() {
    return {
      code: '',
      locale: {
        text: '中文',
        language: 'ch',
      },
      localeCommands: [
        {
          text: '中文',
          language: 'ch',
        },
        {
          text: 'English',
          language: 'en',
        },
      ],
      menuList: [],
      defaultOpenedsArray: [],
      menuListData: [], //菜单接口数据
      asideMenuList: [], //侧边菜单
      parentMenuList: [], //一级菜单
      subMenuList: [], //二级菜单 所有
      unreadTotal: 0, //未读消息数量
      messageList: [], //消息列表
      mainParentMenuList: [], //头部菜单显示主要的选项
      loginDown: false, //登录下拉
      asideMenuActive: '', //左侧选中菜单
      headMenuActive: '', //头部菜单激活
      editableTabsValue: '',
      messageTypeList: [],
      editableTabs: [], //当前打开的tab页面
      currentTab: '', //当前激活tab的name
      shortcutMenu: false, //快捷菜单显示状态
      collapseMenu: false, //菜单折叠，false不折叠
      tabIndex: 1,
      markList: [{ title: '内容管理', icon: 'icon-neirongguanli', hide: true }],
      //登录用户信息
      peopleInfo: {
        userName: '', //账号
        nickName: '',
      },
      mstore: {},
      theme: 'ms-theme-dark',
      propClass: '',
      callbackFun: {},
      resolvePath: {
        1745: {
          routeName: 'MobileTabList',
        },
        707: {
          routeName: 'ContentList',
        },
        411: {
          routeName: 'AdminManger',
        },
        406: {
          routeName: 'RoleManger',
        },
        685: {
          routeName: 'MangerLog',
        },
        //标签体系
        1787: {
          routeName: 'Ma',
          url: '/ma/#/labeling/scheme',
        },
        // 内容标签
        1788: {
          routeName: 'Ma',
          url: '/ma/#/labeling/contentLabel',
        },
        // 客户标签
        1789: {
          routeName: 'Ma',
          url: '/ma/#/labeling/customerLabel',
        },
        // 旅程设计
        1790: {
          routeName: 'Ma',
          url: '/ma/#/nba/journeyDesign',
        },
        // 策略分析
        1791: {
          routeName: 'Ma',
          url: '/ma/#/nba/tracking',
        },
        1799: {
          routeName: 'OJYHContentList',
        },
        1806: {
          routeName: 'OJYHOnlineMeetingList',
        },
        1807: {
          routeName: 'OJYHGBIMeetingList',
        },
        1817: {
          routeName: 'FAQList',
        },
        1820: {
          routeName: 'InformedConsentList',
        },
        1825: {
          routeName: 'OLXYOfflineMeetingList',
        },
        1830: {
          routeName: 'OLXYSurveyList',
        },
        1835: {
          routeName: 'OLXYExerciseList',
        },
        4060: {
          routeName: 'AccountList',
        },
      },
      channelList: [],
      channelName: '',
    }
  },
  computed: {
    messageType(type) {
      var that = this
      return function(type) {
        var dict = that.messageTypeList.find(function(x) {
          return x.dictValue == type
        })
        if (dict) {
          return dict.dictLabel
        }
        return ''
      }
    },
  },
  watch: {
    menuList(n, o) {
      var that = this
      n &&
        n.forEach(function(item, index) {
          item.children
            ? that.subMenuList.push(item)
            : that.parentMenuList.push(item)
        })
      that.parentMenuList = n
      that.markList = []
      that.parentMenuList.forEach((item) => {
        let data = {
          title: item.modelName,
          icon: item.modelIcon,
          hide: true,
        }
        that.markList.push(data)
      })
      localStorage.setItem('markList', JSON.stringify(that.markList))
    },
    parentMenuList(n, o) {
      var that = this
      this.mainParentMenuList = n.slice(0, 5)
      this.asideMenuList = n.filter(function(f) {
        return (
          that.markList.find(function(x) {
            return x.title == f['modelName']
          }) != undefined
        )
      })
    },
  },
  beforeCreate() {},
  created() {
    var markList = localStorage.getItem('markList')
    if (markList) {
      this.markList = JSON.parse(markList)
    }
    localStorage.setItem('markList', JSON.stringify(this.markList))
    if (!this.$global.getChannel(this)) { 
      this.selectChannel()
    }
    this.getChannelList()
  },
  mounted() {
    this.propClass = 'ms-admin-menu-aside-submenu ' + this.theme
    if (localStorage.getItem('theme')) {
      this.theme = localStorage.getItem('theme')
    }
    // 菜单列表
    this.list()
    //获取登录用户信息
    this.managerGet()
    var that = this
    if (localStorage.getItem('editableTabs')) {
      that.editableTabs = JSON.parse(localStorage.getItem('editableTabs'))
      var data = that.editableTabs.find(function(item) {
        if (item.routeName) {
          return (
            item.routeName.indexOf(
              that.$router.history.current.meta[0].parentName
            ) != -1
          )
        } else {
          return false
        }
        // return item.routeName == that.$router.history.current.meta[0].pathName
      })
      if (!data && that.editableTabs.length > 0) {
        var data = that.editableTabs.find(function(item) {
          if (item.modelId) {
            return item.modelId == localStorage.getItem('activeModelId')
          } else {
            return false
          }
          // return item.routeName == that.$router.history.current.meta[0].pathName
        })
      }
      if (data) {
        that.currentTab = data.modelName
        that.headMenuActive = String(data.modelId)
        that.$router.push({ name: data.routeName, query: { url: data.url } })
        that.$nextTick(function() {
          that.asideMenuActive = data.modelId
        })
      }
    }
  },
  destroyed() {},
  methods: {
    getChannelList() {
      var that = this
      getChannelList(that, {})
        .then((res) => {
          if (res.data) {
            res.data.forEach((item) => {
              if (item.code == that.$global.getChannel(that)) {
                that.channelName = item.channelName
              }
            })
            that.channelList = res.data.filter((item) => {
              return item.code != that.$global.getChannel(that)
            })
            console.log('that.channelList', that.channelList)
          } else {
            that.$notify({
              title: '失败',
              message: res.msg,
              type: 'error',
            })
          }
        })
        .finally(() => {})
    },
    handleLanguageClick(val) {
      this.locale.text = val.text
      this.locale.language = val.language
      //语言切换
    },
    markMenu(title, icon) {
      var menu = {
        title: title,
        icon: icon,
      }
      this.markList.push(menu)
      localStorage.setItem('markList', JSON.stringify(this.markList))
      this.callbackFun()
    },
    cancelMarkMenu(title) {
      var index = this.markList.findIndex(function(x) {
        return x.title == title
      })
      this.markList.splice(index, 1)
      localStorage.setItem('markList', JSON.stringify(this.markList))
    },
    handleCommand(theme) {
      this.theme = theme
      this.propClass = 'ms-admin-menu-aside-submenu ' + this.theme
      localStorage.setItem('theme', theme)
    },
    // 菜单列表
    list() {
      var that = this
      queryMenuListData(that, {})
        .then((res) => {
          console.log(res)
          if (res.data) {
            res.data.forEach((item) => {
              if (item.channel == that.$global.getChannel(that)) {
                that.menuList = item.menus
              }
            })
            localStorage.setItem('menuList', JSON.stringify(that.menuList))
          } else {
            that.$notify({
              title: '失败',
              message: res.msg,
              type: 'error',
            })
          }
        })
        .catch((err) => {})
    },
    asideMenuOpen(index, indexPath) {
      // console.log(index)
    },
    // 菜单打开页面
    open(sub) {
      console.log('bbbbbb', sub)
      var that = this
      var result = ''
      result = this.editableTabs.some(function(item, index) {
        return item.modelName == sub.modelName
      })
      if (that.resolvePath[sub.modelId]) {
        sub.routeName = that.resolvePath[sub.modelId].routeName
        if (that.resolvePath[sub.modelId].url) {
          sub.url = that.resolvePath[sub.modelId].url
        }
      } else {
        sub.routeName = 'Main'
      }
      localStorage.setItem('activeModelId', sub.modelId)
      !result ? this.editableTabs.push(sub) : ''

      localStorage.setItem('editableTabs', JSON.stringify(this.editableTabs))
      this.currentTab = sub.modelName
      this.headMenuActive = String(sub.modelId)
      this.$nextTick(function() {
        that.asideMenuActive = sub.modelId
      })
      this.$router.push({ name: sub.routeName, query: { url: sub.url } })
      // 处理其他逻辑
      setTimeout(function() {
        if (document.querySelector('.el-tabs__nav-prev')) {
          document.querySelector('.el-tabs__nav-wrap').style.padding = '0 40px'
        } else {
          document.querySelector('.el-tabs__nav-wrap').style.padding = '0'
        }
      }, 16)
    },
    tabClick(tab) {
      if (!tab.$el.dataset.modelid) {
        this.$router.push({ name: 'Main' })
      }
      this.asideMenuActive = tab.$el.dataset.modelid
      this.headMenuActive = tab.$el.dataset.modelid
      if (tab.$el.dataset.modelid) {
        localStorage.setItem('activeModelId', tab.$el.dataset.modelid)
      }
      this.$router.push({
        name: tab.$el.dataset.routename,
        query: { url: tab.$el.dataset.url },
      })
    },
    // 获取当前菜单的子菜单
    getSubMenu(id) {
      var result = []
      var that = this
      that.subMenuList &&
        that.subMenuList.forEach(function(item) {
          item.modelId == id ? (result = item.children) : ''
        })
      return result
    },
    //关闭tab标签页
    closeTab(targetName) {
      var that = this
      // 关闭的面板是当前激活面板
      var modelId = null
      console.log('that.currentTab', that.currentTab)
      console.log('targetName', targetName)
      if (that.currentTab == targetName) {
        that.editableTabs.forEach(function(tab, index, arr) {
          if (tab.modelName == targetName) {
            modelId = arr[index].modelId
            var nextTab = arr[index + 1] || arr[index - 1]
            if (nextTab) {
              that.currentTab = nextTab.modelName
              that.asideMenuActive = nextTab.modelId
              localStorage.setItem('activeModelId', nextTab.modelId)
              that.headMenuActive = String(nextTab.modelId)
              if (!nextTab.modelId) {
                that.$router.push({ name: 'Main' })
              } else {
                // that.$router.push({ name: nextTab.routeName })
                that.$router.push({
                  name: nextTab.routeName,
                  query: { url: nextTab.url },
                })
              }
            } else {
              that.defaultOpenedsArray = []
              that.currentTab = ''
              that.asideMenuActive = ''
              localStorage.setItem('activeModelId', '')
              that.headMenuActive = ''
              that.$router.push({ name: 'Management' })
            }
          }
        })
      }
      // 去掉关闭的tab
      this.$nextTick(() => {
        that.editableTabs = that.editableTabs.filter(function(tab) {
          return tab.modelName !== targetName
        })
        localStorage.setItem('editableTabs', JSON.stringify(that.editableTabs))
      })

      // 关闭左侧父菜单
      if (that.editableTabs.length) {
        var result = that.editableTabs.every(function(item) {
          return item.modelId !== modelId
        })
        if (result) {
          that.asideMenuList.forEach(function(menu, index, arr) {
            if (menu.modelId == modelId) {
              var flag = false
              that.markList.forEach(function(item, index, array) {
                if (item.title == menu.modelName) {
                  flag = true
                }
              })
              if (!flag) {
                arr.splice(index, 1)
              }
            }
          })
        }
      } else {
        that.asideMenuList = []
      }

      // 判断是否出现左右箭头
      setTimeout(function() {
        if (document.querySelector('.el-tabs__nav-prev')) {
          document.querySelector('.el-tabs__nav-wrap').style.padding = '0 40px'
        } else {
          document.querySelector('.el-tabs__nav-wrap').style.padding = '0'
        }
      }, 16)
    },
    openParentMenuInTitle(title) {
      var data = this.parentMenuList.find(function(menu) {
        return menu.modelName == title
      })
      data && this.openMenu(data)
    },
    // 头部导航打开菜单
    openMenu(menu, index) {
      // this.asideMenuList.some(function (item, index) {
      //   console.log('item',item)
      //   return item.modelId == menu.modelId
      // }) || this.asideMenuList.push(menu)
      // this.getSubMenu(menu.id)[0] && this.$refs.menu.open(this.getSubMenu(menu.id)[0].modelName);
      var children = []
      this.menuList.forEach(function(tab) {
        if (tab.modelId == menu.modelId) {
          children.push(tab)
        }
      })
      this.currentTab =
        children[0].children[0] && children[0].children[0].modelName
      this.open(children[0].children[0])
      var that = this
      setTimeout(function() {
        that.shortcutMenu = false
      }, 50)
      that.$nextTick(function() {
        that.$refs.menu.open(String(menu.modelId))
      })
    },
    managerGet() {
      var that = this
      queryManagerData(that, {})
        .then((res) => {
          if (res.data) {
            that.peopleInfo = res.data
            that.$util.store.set('peopleInfo', res.data)
            localStorage.setItem(
              'permissions',
              JSON.stringify(that.peopleInfo.permissions)
            )
          } else {
            console.log(res)
            that.$notify({
              title: '错误',
              message: res.msg,
              type: 'error',
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exitSystem() {
      var that = this
      this.$confirm('是否确认退出账号？', '退出系统', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'el-button--mini',
        confirmButtonClass: 'el-button--mini',
        type: 'warning',
      }).then(function() {
        localStorage.setItem('markList', [])
        localStorage.setItem('editableTabs', '')
        that.$global.deleToken('token')
        that.$global.deleToken('channel')
        that.$router.push({ name: 'Login' })
      })
    },
    selectChannel(item) {
      location.href = '/Login?changeChannel=true'
      // if (item.type == 'internal') {
      //   that.$global.setChannel(that, item.code)
      //   localStorage.setItem('markList', [])
      //   localStorage.setItem('editableTabs', '')
      //   location.href = '/Management'
      // } else { 
      //   window.open(item.url)
      // }

    },
    //  打开修改密码，退出的模态框
    openModal() {
      this.exitSystem()
    },
    // 显示图标
    addCallBackFun(fun) {
      this.callbackFun = fun
    },
  },
}
</script>

<style>
#index .ms-container {
  height: calc(100vh - 78px) !important;
  /* form height */
  padding-bottom: 100px;
}
.ms-admin-logo {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.ms-admin-logo img {
  padding: 14px 0;
  width: 50px;
}

.ms-admin-logo > div {
  display: flex;
  align-items: center;
}

.ms-admin-logo > div span {
  margin-top: -6px;
  position: absolute;
  margin-left: 10px;
  font-size: 12px;
}

.ms-admin-logo .iconfont {
  color: #fff;
  margin-left: auto;
  margin-right: 20px;
  cursor: pointer;
}

.ms-admin-header-right {
  margin-left: auto;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  line-height: 1;
}

.ms-admin-header-right .ms-admin-people-head {
  width: 30px !important;
  height: 30px !important;
  margin-right: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0099ff;
  color: #fff;
}

.ms-admin-header-menu-shrink {
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 50px;
  border-right: 1px solid rgba(238, 238, 238, 1);
  cursor: pointer;
}

.ms-admin-header-menu-shrink:hover {
  background: rgba(250, 250, 250, 0.2);
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  height: 100vh;
}

.el-menu--collapse {
  height: 100vh;
}

.ms-admin-menu-aside {
  width: auto !important;
  height: 100vh;
  position: relative;
  overflow: visible;
}

.ms-admin-menu-aside .ms-admin-menu .el-menu--inline .is-active {
  border-left: 2px solid #0099ff;
}

.ms-admin-menu-aside .ms-menu-expand i {
  font-weight: bolder;
  font-size: 14px;
  color: #333;
  position: absolute;
  transform: rotate(90deg);
  right: 40%;
}

.ms-admin-menu-aside .ms-menu-expand::before {
  border-color: transparent transparent #eee;
  border-style: none solid solid;
  border-width: 0 30px 22px;
  content: '';
  display: block;
  height: 0;
  left: -10px;
  width: 30px;
}

.ms-admin-menu-aside .el-submenu__title,
.ms-admin-menu-aside .el-menu-item {
  color: rgba(255, 255, 255, 1);
  height: 40px;
  line-height: 40px;
}

.ms-admin-menu-aside .el-submenu__title i {
  color: inherit;
}

.ms-admin-menu-aside .el-submenu__title .iconfont {
  font-size: 19px !important;
}

.ms-admin-menu-aside .el-submenu.is-active .el-submenu__title {
  color: rgba(255, 255, 255, 1) !important;
}

.ms-admin-header {
  display: flex;
  padding: 0;
  background-color: rgba(255, 255, 255, 1);
  height: 50px !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.ms-admin-header .ms-admin-header-menu {
  border-bottom: none;
  position: relative;
}

.ms-admin-header .ms-admin-header-menu > .ms-admin-menu-item > * {
  height: 50px !important;
  line-height: 50px !important;
  border-bottom: none !important;
  font-size: 1em;
}

.ms-admin-header .ms-admin-header-menu .ms-admin-shortcut-menu > li {
  margin: 0;
  padding: 0 20px;
  text-align: left;
  display: -webkit-inline-box;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-weight: normal;
  font-size: 14px;
  color: #333;
}

.ms-admin-header .ms-admin-header-menu .ms-admin-shortcut-menu > li:hover {
  cursor: pointer;
  color: #0099ff;
}

.ms-admin-header-menu .el-submenu__title {
  height: 50px !important;
  line-height: 50px !important;
  display: flex;
  align-items: center;
}

.ms-admin-header-menu .el-submenu__title:hover {
  background-color: #f2f6fc !important;
  color: #409eff !important;
}

.ms-admin-header-menu .el-submenu__title:hover i {
  color: #409eff !important;
}

.ms-admin-header-menu .el-submenu__icon-arrow {
  margin-top: 4px !important;
}

.ms-admin-header-menu-item .el-menu-item:hover {
  background-color: #f2f6fc !important;
  color: #409eff !important;
}

.ms-admin-header-menu-item .el-menu-item:hover i {
  color: #409eff !important;
}

.ms-admin-header-menu-all {
  width: 560px;
  height: auto;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 40px;
}

.ms-admin-header-menu-all .iconfont {
  font-size: 17px;
}

.ms-admin-header-menu-all .ms-admin-header-menu-all-item {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ms-admin-header-menu-all .ms-admin-header-menu-all-item .el-icon-star-on {
  color: #ccc;
  font-size: 17px;
  margin-left: -1px;
  margin-top: 1px;
}

.ms-admin-header-menu-all
  .ms-admin-header-menu-all-item
  .el-icon-star-on:hover {
  color: rgba(64, 158, 255, 1);
}

.ms-admin-header-menu-all .ms-admin-header-menu-all-item .el-icon-star-off {
  color: #ccc;
}

.ms-admin-header-menu-all
  .ms-admin-header-menu-all-item
  .el-icon-star-off:hover {
  color: rgba(64, 158, 255, 1);
}

.ms-admin-header-menu-all .ms-admin-header-menu-all-item:hover {
  color: rgba(64, 158, 255, 1);
}

.ms-admin-menu-aside-submenu .el-menu-item {
  line-height: 40px;
  height: 40px;
}

.el-submenu__title * {
  vertical-align: top;
}

.ms-admin-login-theme .el-dropdown-menu__item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* .el-tabs__nav .el-tabs__item:nth-child(1) span {
  display: none;
} */

.el-tabs__item.is-active {
  background-color: rgba(255, 255, 255, 1);
}

.el-menu {
  border-right: 0px;
}

.ms-admin-logo .layout-class-1 {
  color: white;
  padding-top: 8px;
  color: #ffffff;
  word-wrap: break-word;
  font-family: MicrosoftYaHei-Bold;
  font-weight: bold;
  font-style: italic;
}

.ms-admin-logo .layout-class-2 {
  font-size: 12px;
  font-weight: normal;
}

.top-operate-select .el-menu--popup {
  width: 162px;
  min-width: 162px;
}

.ms-admin-container {
  height: auto;
}

.ms-admin-container > .ms-admin-main {
  padding: 0;
  background-color: #fff;
  z-index: 0;
}

.ms-admin-container > .ms-admin-main .ms-admin-tabs {
  /* form height */
  /* height: calc(100vh - 50px); */
  display: flex;
  flex-direction: column;
}

.ms-admin-container > .ms-admin-main .ms-admin-tabs .el-tabs__content {
  height: 100%;
  overflow: initial;
}

.ms-admin-container
  > .ms-admin-main
  .ms-admin-tabs
  .el-tabs__content
  .el-tab-pane {
  height: 100%;
}

.ms-admin-container > .ms-admin-main .ms-admin-tabs .el-tabs__header {
  background: #fafafa;
  margin-bottom: 0px;
}

.ms-admin-container
  > .ms-admin-main
  .ms-admin-tabs
  .el-tabs__header
  .el-tabs__nav-scroll
  .el-tabs__nav {
  border-left: none;
  border-radius: 0;
}

.ms-admin-container > .ms-admin-main .ms-admin-tabs .ms-admin-refresh {
  float: right;
  width: 40px;
  height: 40px;
  text-align: center;
  border-left: 1px solid #e6e6e6;
  cursor: pointer;
  position: relative;
}

.ms-admin-container > .ms-admin-main .ms-admin-tabs .ms-admin-refresh::before {
  position: absolute;
  left: 34%;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.ms-admin-container > .ms-admin-main .ms-admin-tabs .el-tabs__nav-next,
.ms-admin-container > .ms-admin-main .ms-admin-tabs .el-tabs__nav-prev {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ms-admin-container > .ms-admin-main .ms-admin-tabs .el-tabs__nav-next {
  border-left: 1px solid #e6e6e6;
}

.ms-admin-container > .ms-admin-main .ms-admin-tabs .el-tabs__nav-prev {
  border-right: 1px solid #e6e6e6;
}

.ms-admin-container > .ms-admin-main iframe {
  width: 100%;
  height: 100%;
  border: none !important;
}
</style>
<style>
.ms-admin-menu .is-active {
  border: 0px !important;
}
.vue-treeselect__placeholder,
.vue-treeselect__single-value {
  font-size: 12px;
  padding-top: -8px;
}
.vue-treeselect__control {
  height: 28px;
}
.vue-treeselect__label {
  font-size: 12px;
}

.vue-treeselect__menu-container {
  z-index: 9999 !important;
}
.to-ele {
  font-size: 18px;
  margin: -2px 9px 0px 3px;
}
</style>
