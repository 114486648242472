<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2021-12-27 16:30:53
 * @LastEditTime: 2024-01-29 20:29:36
 * @LastEditors: Ray
-->
<template>
  <div class="class-1" style="display:none;">
    <div class="class-2">
      <div class="class-3">
        <div class="class-4"></div>
        <div class="class-5"></div>
        <div class="class-6"></div>
        <div class="panel">
          <div v-if="alwaysList.length > 0" class="panel-title">
            常用功能
          </div>
          <div class="v-space"></div>
          <div
            class="panel-content"
            style="flex-direction: row;flex-wrap: wrap; flex: unset"
          >
            <div
              class="mitem"
              @click="openParentMethod(item.title)"
              v-for="(item, index) in alwaysList"
              :key="index"
            >
              <!--图标开始-->
              <i :class="['iconfont', item.icon]"></i>
              <div class="item-title">
                {{ item.title }}
              </div>
              <!--文本结束-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="class-56">
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      base: process.env.VUE_APP_URL,
      alwaysList: [], //常用功能列表
      testAsideMenuList: [],
    }
  },
  watch: {},
  created() {
    this.getAlwaysList()
  },
  methods: {
    openParentMethod(title) {
      // window.parent.openParentMenuInTitle(title)
      this.$parent.$parent.$parent.$parent.$parent.$parent.openParentMenuInTitle(
        title
      )
      // console.log(this.$parent.$parent.$parent.$parent.$parent.$parent.openParentMenuInTitle(title))
      // console.log(window.parent)
    },
    getAlwaysList() {
      var markList = localStorage.getItem('markList')
      if (markList) {
        this.alwaysList = JSON.parse(markList)
      }
    },
  },
}
</script>

<style src="@/assets/css/main.css" scoped></style>
