<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2021-12-27 17:13:42
 * @LastEditTime: 2023-10-24 20:27:39
 * @LastEditors: Ray
-->
<template>
  <div id="AdminMangerList" class="ms-index" v-cloak>
    <el-header class="ms-header" height="50px">
      <el-col :span="12">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          v-if="$global.comparePermisson('basic:manager:save')"
          @click="save()"
          >新增</el-button
        >
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          @click="del(selectionList)"
          v-if="$global.comparePermisson('basic:manager:del')"
          :disabled="!selectionList.length"
          >删除</el-button
        >
      </el-col>
    </el-header>
    <el-main class="ms-container">
      <el-table
        v-loading="loading"
        ref="multipleTable"
        border
        :data="dataList"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          {{ emptyText }}
        </template>
        <el-table-column
          type="selection"
          :selectable="isChecked"
          width="40"
        ></el-table-column>
        <!-- <el-table-column
          label="账号"
          min-width="120"
          align="left"
          prop="managerName"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <el-table-column
          label="邮箱"
          min-width="120"
          align="left"
          prop="email"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="isid"
          align="left"
          prop="isid"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- <el-table-column
          label="昵称"
          min-width="120"
          align="left"
          prop="managerNickName"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <el-table-column
          label="角色名称"
          min-width="120"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope" class="ms-row">
            <div>
              <span>
                {{ roleName(scope.row.roles) }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          width="180"
          align="center"
          prop="createDate"
          :formatter="createDateFormat"
        >
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="180">
          <template
            slot-scope="scope"
          >
            <el-link
              type="primary"
              :underline="false"
              v-if="$global.comparePermisson('basic:manager:update')"
              :disabled="
                $util.store.get('peopleInfo').isid ==
                  scope.row.isid
              "
              @click="save(scope.row.id)"
              >编辑</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              v-if="$global.comparePermisson('basic:manager:del')"
              :disabled="
                $util.store.get('peopleInfo').isid ==
                  scope.row.isid
              "
              @click="del([scope.row])"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[5, 10, 20]"
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        class="ms-pagination"
        @current-change="currentChange"
        @size-change="sizeChange"
      >
      </el-pagination>
    </el-main>
    <el-dialog
      id="form"
      ref="form"
      :visible.sync="dialogVisible"
      append-to-body
      :before-close="handleClose"
      width="50%"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="mini"
      >
        <!-- <el-form-item label="管理员账号" prop="managerName">
          <el-input
            v-model="form.managerName"
            :disabled="nameDisabled"
            :style="{ width: '100%' }"
            :clearable="true"
            placeholder="请输入管理员账号"
          >
          </el-input>
          <div class="ms-form-tip">管理员账号必须填写，长度最少为6个字符！</div>
        </el-form-item> -->
        <el-form-item label="管理员邮箱" prop="email">
          <el-input
            v-model="form.email"
            :disabled="nameDisabled"
            :style="{ width: '100%' }"
            :clearable="true"
            placeholder="请输入管理员邮箱"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="管理员ISID" prop="isid">
          <el-input
            v-model="form.isid"
            :disabled="nameDisabled"
            :style="{ width: '100%' }"
            :clearable="true"
            placeholder="请输入管理员管理员ISID"
          >
          </el-input>
        </el-form-item>

        <!-- <el-form-item label="管理员昵称" prop="managerNickName">
          <el-input
            v-model="form.managerNickName"
            :disabled="false"
            :style="{ width: '100%' }"
            :clearable="true"
            placeholder="请输入管理员昵称"
          >
          </el-input>
          <div class="ms-form-tip">管理员昵称必须填写，长度为1-12个字符！</div>
        </el-form-item> -->
        <!-- <el-form-item v-if="!form.id" label="管理员密码" prop="managerPassword">
          <el-input
            v-model="form.managerPassword"
            type="password"
            :disabled="false"
            :style="{ width: '100%' }"
            :clearable="true"
            placeholder="请输入管理员密码"
          >
          </el-input>
          <div class="ms-form-tip">管理员密码必须填写，长度为6-30个字符！</div>
        </el-form-item> -->
        <el-form-item label="角色名称" prop="roleIds">
          <el-select
            v-model="form.roleIds"
            :style="{ width: '' }"
            :filterable="false"
            :disabled="false"
            :multiple="true"
            :clearable="true"
            placeholder="请选择角色名称"
          >
            <el-option
              v-for="item in managerRoleidOptions"
              :key="item.id"
              :value="item.id"
              :label="item.roleName"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="handleClose">取 消</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="saveAcount"
          :loading="saveDisabled"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryManagerListData,
  getAdminDetail,
  getManagerRole,
  saveMangerAcount,
  updateMangerAcount,
  delMangerAcount,
} from '@/api/System/manger.js'
export default {
  name: 'AdminMangerList',
  props: {},
  data() {
    return {
      dataList: [],
      //管理员管理列表
      selectionList: [],
      //管理员管理列表选中
      total: 0,
      //总记录数量
      pageSize: 10,
      //页面数量
      currentPage: 1,
      //初始页
      // mananger: ms.manager,
      loading: false,
      //加载状态
      emptyText: '',
      //提示文字
      //搜索表单
      form: {
        // 管理员名
        // managerName: '',
        // 管理员昵称
        // managerNickName: '',
        // 管理员密码
        // managerPassword: '',
        // 角色名称
        email: '',
        isid:'',
        roleIds: [],
      },
      saveDisabled: false,
      dialogVisible: false,
      nameDisabled: false,
      managerRoleidOptions: [],
      rules: {
        // 管理员账号
        // managerName: [
        //   {
        //     required: true,
        //     message: '管理员账号必须填写',
        //   },
        //   {
        //     min: 6,
        //     max: 128,
        //     message: '管理员账号长度最少为6个字符!',
        //     trigger: 'change',
        //   },
        // ],
        email: [
          {
            required: true,
            message: '管理员邮箱必须填写',
          },
          {
            type:'email',
            message: '请输入正确的管理员邮箱!',
            trigger: 'blur',
          },
        ],
        isid: [
          {
            required: true,
            message: '管理员ISID必须填写',
          },
        ],
        // 管理员昵称
        // managerNickName: [
        //   {
        //     required: true,
        //     message: '管理员昵称必须填写',
        //   },
        //   {
        //     min: 1,
        //     max: 12,
        //     message: '管理员昵称长度为1-12个字符！',
        //     trigger: 'change',
        //   },
        // ],
        // 管理员密码
        // managerPassword: [
        //   {
        //     required: true,
        //     message: '管理员密码必须填写',
        //   },
        //   {
        //     min: 6,
        //     max: 30,
        //     message: '管理员密码长度为6-30个字符!',
        //     trigger: 'change',
        //   },
        // ],
        // 角色名称
        roleIds: [
          {
            required: true,
            message: '角色名称必须填写',
          },
        ],
      },
    }
  },
  watch: {},
  created() {
    this.list()
  },
  methods: {
    createDateFormat(row, column, cellValue, index) {
      var retValue = ''
      if (cellValue) {
        retValue = this.$util.date.fmt(cellValue, 'yyyy-MM-dd')
      }
      return retValue
    },
    roleName(roles) {
      let roleName = []
      if (roles.length > 0) {
        roles.forEach((item) => {
          roleName.push(item.roleName)
        })
      }
      return roleName.toString()
    },
    //查询列表
    list() {
      var that = this
      var page = {
        pageNo: that.currentPage,
        pageSize: that.pageSize,
      }
      that.loading = true
      setTimeout(function() {
        queryManagerListData(that, page).then(function(data) {
          that.loading = false
          if (data.data.total <= 0) {
            that.emptyText = '暂无数据'
            that.dataList = []
          } else {
            that.emptyText = ''
            that.total = data.data.total
            that.dataList = data.data.rows
          }
        })
      }, 500)
    },
    //管理员管理列表选中
    handleSelectionChange(val) {
      this.selectionList = val
    },
    //不能删除自己
    isChecked(row, index) {
      if (this.$util.store.get('peopleInfo').isid == row.isid) {
        return false
      } else {
        return true
      }
    },
    //删除
    del(row) {
      var that = this
      that
        .$confirm('此操作将永久删除所选内容, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(function() {
          that.loading = true
          let data = []
          row.forEach((item) => {
            data.push(item.id)
          })
          delMangerAcount(that, data)
            .then(function(data) {
              if (data.result) {
                that.$notify({
                  type: 'success',
                  message: '删除成功!',
                }) //删除成功，刷新列表
                that.list()
              } else {
                that.loading = false
                that.$notify({
                  title: '失败',
                  message: data.msg,
                  type: 'warning',
                })
              }
            })
            .catch((error) => {
              that.loading = false
            })
        })
    },
    //新增
    save(id) {
      this.open(id)
    },
    saveAcount() {
      var that = this
      this.$refs.form.validate(function(valid) {
        if (valid) {
          that.saveDisabled = true
          var data = JSON.parse(JSON.stringify(that.form))
          if (that.form.id > 0) {
            updateMangerAcount(that, data)
              .then(function(res) {
                if (res.data.result) {
                  that.$notify({
                    title: '成功',
                    message: '保存成功',
                    type: 'success',
                  })
                  that.saveDisabled = false
                  that.dialogVisible = false
                  that.list()
                } else {
                  that.$notify({
                    title: '失败',
                    message: res.data.msg,
                    type: 'warning',
                  })
                  that.saveDisabled = false
                }
              })
              .catch((err) => {
                that.saveDisabled = false
              })
          } else {
            saveMangerAcount(that, data)
              .then(function(res) {
                if (res.data.result) {
                  that.$notify({
                    title: '成功',
                    message: '保存成功',
                    type: 'success',
                  })
                  that.saveDisabled = false
                  that.dialogVisible = false
                  that.list()
                } else {
                  that.$notify({
                    title: '失败',
                    message: res.data.msg,
                    type: 'warning',
                  })
                  that.saveDisabled = false
                }
              })
              .catch((err) => {
                that.saveDisabled = false
              })
          }
        } else {
          return false
        }
      })
    },
    //pageSize改变时会触发
    sizeChange(pagesize) {
      this.loading = true
      this.pageSize = pagesize
      this.list()
    },
    //currentPage改变时会触发
    currentChange(currentPage) {
      this.loading = true
      this.currentPage = currentPage
      this.list()
    },
    open(id) {
      this.nameDisabled = false
      this.managerRoleidOptionsGet()
      if (id) {
        this.getAdminDetail(id)
      }

      this.$nextTick(function() {
        this.dialogVisible = true
      })
    },
    //获取当前管理员管理
    getAdminDetail(id) {
      var that = this
      getAdminDetail(that, {
        id: id,
      }).then(function(data) {
        if (data.data.id) {
          that.form = data.data
          that.form.roleIds = data.data.roleIds
          if (that.form.id) {
            that.nameDisabled = true
          }
          console.log(that.form)
        }
      })
    },
    //获取managerRoleid数据源
    managerRoleidOptionsGet: function() {
      var that = this
      getManagerRole(that, {pageSize:1000}).then(function(data) {
        that.managerRoleidOptions = data.data.rows
      })
    },
    handleClose() {
      this.form.id = ''
      this.$refs.form.resetFields()
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped>
.el-select-dropdown__list {
  padding-bottom: 20px;
}
.el-select-dropdown__wrap {
  margin-bottom: 0 !important;
}
</style>
