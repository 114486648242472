/*
 * @Description:
 * @Author: Ray
 * @Date: 2021-12-27 11:08:37
 * @LastEditTime: 2023-06-05 09:43:45
 * @LastEditors: Ray
 */
import global from '@/global/global.js'

export const checkLogin = (self, data) => {
  const url = '/api/auth'
  return global.httpPostWithToken(self, url, data)
}

export const ssoLogin = (self, data) => {
  const url = '/api/auth/sso'
  return global.httpPostWithToken(self, url, data)
}

export const queryMenuListData = (self, data) => {
  const url = '/api/auth/current/model'
  return global.httpGetWithToken(self, url, data)
}

export const getChannelList = (self, data) => {
  const url = '/api/auth/current/channel'
  return global.httpGetWithToken(self, url, data)
}

export const getCode = (self, data) => {
  const url = '/api/code'
  return global.httpGetWithToken(self, url, data)
}

export const queryManagerData = (self, data) => {
  const url = '/api/auth/current'
  return global.httpGetWithToken(self, url, data)
}
