<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2021-12-27 17:13:42
 * @LastEditTime: 2023-10-24 20:28:46
 * @LastEditors: Ray
-->
<template>
  <div id="RoleMangerList" class="ms-index" v-cloak>
    <el-header class="ms-header" height="50px">
      <el-col :span="12">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          v-if="
            $global.comparePermisson(
              'basic:role:save'
            )
          "
          @click="save()"
          >新增</el-button
        >
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          @click="del(selectionList)"
          v-if="
            $global.comparePermisson(
              'basic:role:del'
            )
          "
          :disabled="!selectionList.length"
          >删除</el-button
        >
      </el-col>
    </el-header>
    <div class="ms-search" style="padding: 20px 10px 0 10px;">
      <el-row>
        <el-form :model="form" ref="searchForm" label-width="120px" size="mini">
          <el-row>
            <el-col :span="8">
              <el-form-item label="角色名称" prop="roleName">
                <el-input
                  v-model="form.roleName"
                  :disabled="false"
                  :clearable="true"
                  placeholder="请输入角色名称"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16" style="text-align: right">
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="
                  currentPage = 1
                  list()
                "
                >查询</el-button
              >
              <el-button @click="rest" icon="el-icon-refresh" size="mini"
                >重置</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </div>
    <el-main class="ms-container">
      <el-table
        v-loading="loading"
        ref="multipleTable"
        border
        :data="dataList"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          {{ emptyText }}
        </template>
        <el-table-column
          type="selection"
          :selectable="isChecked"
          width="40"
        ></el-table-column>
        <el-table-column
          label="角色名称"
          align="left"
          prop="roleName"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="120" align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              v-if="
                $global.comparePermisson(
                  'basic:role:update'
                )
              "
              @click="save(scope.row.id)"
              :disabled="$util.store.get('peopleInfo').roles.indexOf(scope.row.roleCode) != -1"
              >编辑</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              v-if="
                $global.comparePermisson(
                  'basic:role:del'
                )
              "
              :disabled="$util.store.get('peopleInfo').roles.indexOf(scope.row.roleCode) != -1"
              @click="del([scope.row])"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[5, 10, 20]"
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        class="ms-pagination"
        @current-change="currentChange"
        @size-change="sizeChange"
      >
      </el-pagination>
    </el-main>
  </div>
</template>

<script>
import { getManagerRole, deleteManagerRole } from '@/api/System/manger.js'
export default {
  name: 'RoleMangerList',
  props: {},
  data() {
    return {
      dataList: [],
      //管理员管理列表
      selectionList: [],
      //管理员管理列表选中
      total: 0,
      //总记录数量
      pageSize: 10,
      //页面数量
      currentPage: 1,
      //初始页
      // mananger: ms.manager,
      loading: false,
      //加载状态
      emptyText: '',
      //提示文字
      //搜索表单
      form: {
        // 角色名称
        roleName: null,
      },
    }
  },
  watch: {},
  created() {
    this.list()
  },
  methods: {
    //查询列表
    list() {
      var that = this
      var page = {
        pageNo: that.currentPage,
        pageSize: that.pageSize,
      }
      var form = JSON.parse(JSON.stringify(that.form))

      for (let key in form) {
        if (!form[key]) {
          delete form[key]
        }
      }

      history.replaceState(
        {
          form: form,
          page: page,
          total: that.total,
        },
        ''
      )
      that.loading = true
        getManagerRole(that, Object.assign({}, that.form, page))
          .then(function(data) {
            that.loading = false
            if (data.data.total <= 0) {
              that.emptyText = '暂无数据'
              that.dataList = []
            } else {
              that.emptyText = ''
              that.total = data.data.total
              that.dataList = data.data.rows
            }
          })
          .catch((err) => {
            that.loading = false
          })
    },
    //管理员管理列表选中
    handleSelectionChange(val) {
      this.selectionList = val
    },
    //不能删除自己
    isChecked(row, index) {
      if (this.$util.store.get('peopleInfo').roleId == row.id) {
        return false
      } else {
        return true
      }
    },
    //删除
    del(row) {
      var that = this
      that
        .$confirm('此操作将永久删除所选内容, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(function () {
          let data = []
          row.forEach(item => { 
            data.push(item.id)
          })
          deleteManagerRole(that, data)
            .then(function(res) {
              that.loading = true
              if (res.result) {
                that.$notify({
                  type: 'success',
                  message: '删除成功!',
                }) //删除成功，刷新列表
                that.list()
              } else {
                that.loading = false
                that.$notify({
                  title: '失败',
                  message: res.msg,
                  type: 'warning',
                })
              }
            })
            .catch((err) => {
              that.loading = false
            })
        })
    },
    //新增
    save (id) {
      console.log('id',id)
      if (id) {
        this.$router.push({ name: 'RoleMangerForm', query: { id: id } })
      } else {
        this.$router.push({ name: 'RoleMangerForm' })
      }
    },
    //pageSize改变时会触发
    sizeChange(pagesize) {
      this.loading = true
      this.pageSize = pagesize
      this.list()
    },
    //currentPage改变时会触发
    currentChange(currentPage) {
      this.loading = true
      this.currentPage = currentPage
      this.list()
    },
    //获取managerRoleid数据源
    managerRoleidOptionsGet: function() {
      var that = this
      getManagerRole(that, {}).then(function(data) {
        that.managerRoleidOptions = data.data.rows
      })
    },
    //重置表单
    rest() {
      this.currentPage = 1
      this.$refs.searchForm.resetFields()

      if (history.hasOwnProperty('state') && history.state) {
        this.form = history.state.form
        this.total = history.state.total
        this.currentPage = history.state.page.pageNo
        this.pageSize = history.state.page.pageSize
      }

      this.list()
    },
  },
}
</script>

<style scoped></style>
