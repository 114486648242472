/*
 * @Description:
 * @Author: Ray
 * @Date: 2021-12-28 15:32:09
 * @LastEditTime: 2023-02-23 16:40:09
 * @LastEditors: Ray
 */

import global from '@/global/global.js'

//
export const queryManagerListData = (self, data) => {
  const url = '/api/manager'
  return global.httpGetWithToken(self, url, data)
}

export const getAdminDetail = (self, data) => {
  const url = '/api/manager/' + data.id
  return global.httpGetWithToken(self, url, data)
}

export const queryModelListData = (self, data) => {
  const url = '/api/model'
  return global.httpGetWithToken(self, url, data)
}

export const getManagerRole = (self, data) => {
  const url = '/api/role'
  return global.httpGetWithToken(self, url, data)
}
export const deleteManagerRole = (self, data) => {
  const url = '/api/role'
  return global.httpDeleteWithToken(self, url, data)
}

export const saveRoleAcount = (self, data) => {
  const url = '/api/role'
  return global.httpPostWithToken(self, url, data)
}
export const updateRoleAcount = (self, data) => {
  const url = '/api/role/' + data.id
  return global.httpPutWithToken(self, url, data)
}

export const getRoleDetail = (self, id) => {
  const url = '/api/role/' + id
  return global.httpGetWithToken(self, url, {})
}

export const saveMangerAcount = (self, data) => {
  const url = '/api/manager'
  return global.httpPostWithToken(self, url, data)
}
export const updateMangerAcount = (self, data) => {
  const url = '/api/manager/' + data.id
  return global.httpPutWithToken(self, url, data)
}
export const delMangerAcount = (self, data) => {
  const url = '/api/manager'
  return global.httpDeleteWithToken(self, url, data)
}
