/*
 * @Description:
 * @Author: Ray
 * @Date: 2021-12-28 15:32:09
 * @LastEditTime: 2023-02-23 17:15:25
 * @LastEditors: Ray
 */

import global from '@/global/global.js'

//
export const queryManagerLogListData = (self, data) => {
  const url = '/api/log'
  return global.httpGetWithToken(self, url, data)
}

export const getLogDetail = (self, data) => {
  const url = '/api/log/' + data.id
  return global.httpGetWithToken(self, url, data)
}
