<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2021-12-27 17:13:42
 * @LastEditTime: 2023-10-24 20:28:04
 * @LastEditors: Ray
-->
<template>
  <div id="RoleMangerList" class="ms-index" v-cloak>
    <el-header class="ms-header ms-tr" height="50px">
      <el-button
        size="mini"
        icon="iconfont icon-fanhui"
        plain
        onclick="javascript:history.go(-1)"
        >返回</el-button
      >
    </el-header>
    <el-main class="ms-container">
      <el-form
        ref="form"
        :model="form"
        v-loading="loading"
        label-width="120px"
        label-position="right"
        size="small"
      >
        <el-row :gutter="0" justify="start" align="top">
          <el-col :span="12">
            <el-form-item label="标题" prop="logTitle">
              <el-input
                v-model="form.logTitle"
                :disabled="true"
                :style="{ width: '100%' }"
                :clearable="true"
                placeholder=""
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="IP" prop="logIp">
              <el-input
                v-model="form.logIp"
                :disabled="true"
                :style="{ width: '100%' }"
                :clearable="true"
                placeholder=""
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0" justify="start" align="top">
          <el-col :span="12">
            <el-form-item label="请求方法" prop="logMethod">
              <el-input
                v-model="form.logMethod"
                :disabled="true"
                :style="{ width: '100%' }"
                :clearable="true"
                placeholder=""
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请求方式" prop="logRequestMethod">
              <el-select
                v-model="form.logRequestMethod"
                :style="{ width: '100%' }"
                :filterable="false"
                :disabled="true"
                :multiple="false"
                :clearable="true"
                placeholder=""
              >
                <el-option
                  v-for="item in logRequestMethodOptions"
                  :key="item.value"
                  :value="item.value"
                  :label="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0" justify="start" align="top">
          <el-col :span="12">
            <el-form-item label="请求地址" prop="logUrl">
              <el-input
                v-model="form.logUrl"
                :disabled="true"
                :style="{ width: '100%' }"
                :clearable="true"
                placeholder=""
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请求状态" prop="logStatus">
              <el-select
                v-model="form.logStatus"
                :style="{ width: '100%' }"
                :filterable="false"
                :disabled="true"
                :multiple="false"
                :clearable="true"
                placeholder=""
              >
                <el-option
                  v-for="item in logStatusOptions"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0" justify="start" align="top">
          <el-col :span="12">
            <el-form-item label="业务类型" prop="logBusinessType">
              <el-select
                v-model="form.logBusinessType"
                :style="{ width: '100%' }"
                :filterable="false"
                :disabled="true"
                :multiple="false"
                :clearable="true"
                placeholder=""
              >
                <el-option
                  v-for="item in logBusinessTypeOptions"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户类型" prop="logUserType">
              <el-select
                v-model="form.logUserType"
                :style="{ width: '100%' }"
                :filterable="false"
                :disabled="true"
                :multiple="false"
                :clearable="true"
                placeholder=""
              >
                <el-option
                  v-for="item in logUserTypeOptions"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0" justify="start" align="top">
          <el-col :span="12">
            <el-form-item label="操作人员" prop="logUser">
              <el-input
                v-model="form.logUser"
                :disabled="true"
                :style="{ width: '100%' }"
                :clearable="true"
                placeholder=""
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所在地区" prop="logLocation">
              <el-input
                v-model="form.logLocation"
                :disabled="true"
                :style="{ width: '100%' }"
                :clearable="true"
                placeholder=""
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0" justify="start" align="top">
          <el-col :span="12">
            <el-form-item label="创建时间" prop="logUser">
              <el-input
                v-model="form.createDate"
                :disabled="true"
                :style="{ width: '100%' }"
                :clearable="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0" justify="start" align="top">
          <el-col :span="12">
            <el-form-item label="请求参数" prop="logParam">
              <el-input
                type="textarea"
                :rows="5"
                :disabled="true"
                v-model="form.logParam"
                :style="{ width: '100%' }"
                placeholder=""
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="返回参数" prop="logResult">
              <el-input
                type="textarea"
                :rows="5"
                :disabled="true"
                v-model="form.logResult"
                :style="{ width: '100%' }"
                placeholder=""
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="错误消息" prop="logErrorMsg">
          <el-input
            type="textarea"
            :rows="5"
            :disabled="true"
            v-model="form.logErrorMsg"
            :style="{ width: '100%' }"
            placeholder=""
          >
          </el-input>
        </el-form-item>
      </el-form>
    </el-main>
  </div>
</template>

<script>
import { getLogDetail } from '@/api/System/log.js'
export default {
  name: 'MangerLogDetail',
  props: {},
  data() {
    return {
      loading: false,
      saveDisabled: false,
      //表单数据
      form: {
        // 标题
        logTitle: '',
        // IP
        logIp: '',
        // 请求方法
        logMethod: '',
        // 请求方式
        logRequestMethod: '',
        // 请求地址
        logUrl: '',
        // 请求状态
        logStatus: '',
        // 业务类型
        logBusinessType: 'insert',
        // 用户类型
        logUserType: '',
        // 操作人员
        logUser: '',
        // 所在地区
        logLocation: '',
        // 请求参数
        logParam: '',
        // 返回参数
        logResult: '',
        // 错误消息
        logErrorMsg: '',
      },
      logRequestMethodOptions: [
        { value: 'get' },
        { value: 'post' },
        { value: 'put' },
      ],
      logStatusOptions: [
        { value: 'success', label: '成功' },
        { value: 'error', label: '失败' },
      ],
      logBusinessTypeOptions: [
        { value: 'insert', label: '新增' },
        { value: 'delete', label: '删除' },
        { value: 'update', label: '修改' },
        { value: 'other', label: '其他' },
      ],
      logUserTypeOptions: [
        { value: 'other', label: '其他' },
        { value: 'manage', label: '管理员' },
        { value: 'people', label: '会员' },
      ],
    }
  },
  watch: {},
  created() {
    if (this.$util.getParameter('id')) {
      this.getLogDetail(this.$util.getParameter('id'))
    }
  },
  methods: {
    getLogDetail(id) {
      var that = this
      that.loading = true
      getLogDetail(that, {
        id: id,
      })
        .then(function(data) {
           that.loading = false
          if (data.data.id) {
            that.form = data.data
          }
        })
        .catch((err) => {
          that.loading = false
        })
    },
  },
}
</script>

<style scoped></style>
