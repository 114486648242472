<template>
  <div id="app" class="index">
    <!--大容器开始-->
    <div class="class-1" @keydown.13="login">
      <!--大容器开始-->
      <div v-show="!show" class="class-2" style="overflow: hidden;">
        <div class="class-3">
          <img src="../../assets/images/1577694374686.png" class="class-4" />
        </div>
        <div class="class-5">
          <div class="class-6">
            <div class="class-7 removeLine">登录</div>
            <div class="login-password" v-if="!showSSO">
              <el-form
                class="login-from"
                :model="form"
                ref="form"
                :rules="rules"
              >
                <div class="class-8">
                  <el-form-item prop="managerName">
                    <span
                      :class="[
                        form.managerName
                          ? 'input input--hoshi input--filled'
                          : 'input input--hoshi ',
                      ]"
                    >
                      <input
                        v-model="form.managerName"
                        class="input__field input__field--hoshi"
                        type="text"
                        id="input-name"
                      />
                      <label
                        class="input__label input__label--hoshi input__label--hoshi-color-1"
                        for="input-name"
                      >
                        <span
                          class="input__label-content input__label-content--hoshi"
                          >账号</span
                        >
                      </label>
                    </span>
                  </el-form-item>
                </div>
                <div class="class-13">
                  <el-form-item prop="managerPassword">
                    <span
                      :class="[
                        form.managerPassword
                          ? 'input input--hoshi input--filled'
                          : 'input input--hoshi ',
                      ]"
                    >
                      <input
                        v-model="form.managerPassword"
                        class="input__field input__field--hoshi"
                        type="password"
                        id="input-password"
                      />
                      <label
                        class="input__label input__label--hoshi input__label--hoshi-color-1"
                        for="input-password"
                      >
                        <span
                          class="input__label-content input__label-content--hoshi"
                          >密码</span
                        >
                      </label>
                    </span>
                  </el-form-item>
                </div>
                <div class="class-16">
                  <div class="class-17">
                    <el-form-item prop="rand_code">
                      <span
                        :class="[
                          form.rand_code
                            ? 'input input--hoshi input--filled'
                            : 'input input--hoshi ',
                        ]"
                      >
                        <input
                          v-model="form.rand_code"
                          class="input__field input__field--hoshi"
                          type="text"
                          id="input-rand-code"
                        />
                        <label
                          class="input__label input__label--hoshi input__label--hoshi-color-1"
                          for="input-rand-code"
                        >
                          <span
                            class="input__label-content input__label-content--hoshi removeLine"
                            >验证码</span
                          >
                        </label>
                      </span>
                    </el-form-item>
                  </div>
                  <div class="class-20">
                    <img
                      :src="`data:image/png;base64,${codeBase64}`"
                      class="code-img"
                      @click="code"
                    />
                  </div>
                  <div class="class-21">
                    <div @click="code" class="class-22">
                      <div class="class-23 removeLine">
                        看不清？
                      </div>
                      <div class="class-24 removeLine">
                        换一张
                      </div>
                    </div>
                  </div>
                </div>
              </el-form>
              <div class="class-25 removeLine"></div>
              <el-button
                @click="login"
                style="width: 100%;"
                type="primary"
                :loading="loading"
                class="class-26"
              >
                {{ loading ? '登录中' : '登录' }}
              </el-button>
            </div>
            <div class="login-sso" v-else>
              <div style="height: 320px;display: flex;justify-content: center;">
                <el-button
                  style="width: 160px;
                  height: 160px;
                  border-radius: 50%;
                  font-size: 24px;
                  margin: auto;"
                  @click="loginSSO"
                  type="primary"
                  :loading="loading"
                  class="class-26"
                >
                  {{ loading ? '登录中' : 'SSO登录' }}
                </el-button>
              </div>
            </div>
            <!-- <div
              style="text-align: center;
    padding-top: 20px;"
            >
              <div style="padding-bottom: 5px;">其他登录方式:</div>
              <span
                v-if="!showSSO"
                style="color: #0099ff;cursor: pointer;"
                @click="showSSO = !showSSO"
                >SSO登录</span
              >
              <span
                v-else
                style="color: #0099ff;cursor: pointer;"
                @click="showSSO = !showSSO"
                >账号登录</span
              >
            </div> -->
          </div>
        </div>
      </div>
      <transition name="el-fade-in-linear">
        <div
          v-show="show"
          class="class-2 transition-box"
          style="overflow: hidden;"
        >
          <div class="channel-box">
            <div class="channel-title class-7">
              <div
                style="display: inline-block;
        height: 100%;padding-top: 20px;"
              >
                渠道
              </div>
            </div>
            <div class="" style="padding: 20px 0 20px 30px;">
              请选择你想要管理的渠道
            </div>
            <!-- <div class="type-style">
              内部渠道
            </div> -->
            <div class="channel-content">
              <div
                class="channel-item"
                v-for="(item, index) in channelList"
                :key="index"
                @click="goChannel(item)"
              >
                <img class="channel-item-img" :src="item.img" alt="" />
                <span class="channel-item-name">{{ item.channelName }}</span>
              </div>
            </div>
            <!-- <div class="type-style">
              外部渠道
            </div>
            <div class="channel-content">
              <div
              v-if="item.type=='external_link'"
                class="channel-item"
                v-for="(item, index) in channelList"
                :key="index"
                @click="goChannel(item)"
              >
                <img class="channel-item-img" :src="item.img" alt="" />
                <span class="channel-item-name">{{ item.channelName }}</span>
              </div>
            </div> -->
          </div>
        </div>
      </transition>
    </div>
    <!--大容器结束-->
  </div>
  <!--大容器结束-->
</template>
<script>
import { checkLogin, ssoLogin, getCode, getChannelList } from '@/api/layout.js'
export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      show: false,
      expires_in: '',
      codeBase64: '',
      channelList: [],
      form: {
        managerName: '',
        managerPassword: '',
        rand_code: '',
        rememberMe: false,
        imgUUID: '',
      },
      rules: {
        managerName: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          {
            min: 1,
            max: 30,
            message: '长度不能超过30个字符',
            trigger: 'change',
          },
        ],
        managerPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 1,
            max: 30,
            message: '长度不能超过30个字符',
            trigger: 'change',
          },
        ],
        rand_code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 1, max: 4, message: '长度不能超过4个字符', trigger: 'change' },
        ],
      },
      showSSO: true,
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {
    let that = this
    localStorage.setItem('markList', [])
    localStorage.setItem('editableTabs', '')
    localStorage.setItem('activeModelId', '')
    if (this.$util.getParameter('changeChannel') == 'true') {
      that.$global.deleToken('channel')
    }
    if (this.$global.getToken(this) && this.$global.getChannel(this)) {
      location.href = '/Management'
    } else if (this.$global.getToken(this) && !this.$global.getChannel(this)) {
      that.getChannelList()
    }
    // this.code()
    if (this.$util.getParameter('code')) {
      this.loading = true
      this.checkSSO()
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    getChannelList() {
      var that = this
      getChannelList(that, {})
        .then((res) => {
          if (res.data) {
            console.log('res.data', res.data)
            that.show = true
            that.channelList = res.data
          } else {
            that.$notify({
              title: '失败',
              message: res.msg,
              type: 'error',
            })
            that.loading = false
          }
        })
        .finally(() => {
          that.loading = false
        })
    },
    goChannel(item) {
      let that = this
      if (item.type == 'internal') {
        this.$global.setChannel(that, item.code)
      }
      if (item.url) {
        location.href = item.url
      } else {
        location.href = '/Management'
      }
      // if (item.type == 'internal') {
      //   this.$global.setChannel(that, item.code)
      //   location.href = '/Management'
      // } else {
      //   window.open(item.url)
      //   // location.href = item.url
      // }
    },
    //登录
    login() {
      var that = this
      that.$refs.form.validate(function(valid) {
        console.log('valid', valid)
        console.log(that.form)
        if (valid) {
          that.loading = true
          checkLogin(that, {
            managerName: that.form.managerName,
            managerPassword: that.form.managerPassword,
            rand_code: that.form.rand_code,
            // rememberMe: that.form.rememberMe,
            uuid: that.form.imgUUID,
          })
            .then((res) => {
              if (res.data) {
                that.$global.setToken(
                  'token',
                  res.data.access_token,
                  res.data.expires_in
                )
                that.expires_in = res.data.expires_in
                that.getChannelList()
                // location.href = '/Management'
              } else {
                that.$notify({
                  title: '失败',
                  message: res.msg,
                  type: 'error',
                })
                that.code()
                that.loading = false
              }
            })
            .finally(() => {
              that.code()
              that.loading = false
            })
        }
      })
    },
    loginSSO() {
      this.loading = true
      window.location.href =
        process.env.VUE_APP_SIGN_URL +
        '?client_id=' +
        process.env.VUE_APP_CLIENT_ID +
        '&response_type=' +
        process.env.VUE_APP_RESPONSE_TYPEL +
        '&redirect_uri=' +
        process.env.VUE_APP_REDIRECT_URL +
        '&state=sso'
    },
    checkSSO() {
      var that = this
      let data = {
        code: this.$util.getParameter('code'),
        redirectUrl: process.env.VUE_APP_REDIRECT_URL,
      }
      ssoLogin(that, data)
        .then((res) => {
          if (res.data) {
            that.$global.setToken(
              'token',
              res.data.access_token,
              res.data.expires_in
            )
            that.expires_in = res.data.expires_in
            window.location.replace(process.env.VUE_APP_REDIRECT_URL)
          } else {
            that.$notify({
              title: '失败',
              message: res.msg,
              type: 'error',
            })
            that.loading = false
          }
        })
        .catch((err) => {
          that.loading = false
        })
    },
    //获取验证码
    code() {
      let that = this
      getCode(that, {})
        .then((res) => {
          if (res.data) {
            this.codeBase64 = res.data.img
            this.form.imgUUID = res.data.imgUUID
          } else {
            that.$notify({
              title: '失败',
              message: res.msg,
              type: 'error',
            })
            that.loading = false
          }
        })
        .finally(() => {
          that.loading = false
        })
    },
    //初始
    initial() {
      this.form.managerName = localStorage.getItem('managerName')
      this.form.managerPassword = localStorage.getItem('managerPassword')
      // top.location != self.location ? (top.location = self.location) : ''
    },
  },
}
</script>
<style>
.type-style {
  border-bottom: 1px dashed black;
  margin: 0px 45px;
  padding-bottom: 10px;
}
.channel-box {
  width: 100%;
  height: 100%;
}
.channel-title {
  width: 100%;
  height: 30px;
  padding-left: 20px;
  /* background: rgb(179, 216, 255); */
}
.channel-content {
  display: flex;
  flex-flow: row wrap;
  flex-direction: inherit;
  justify-content: flex-start;
  /* padding-top: 20px; */
  padding: 20px 45px;
}
.channel-item {
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  width: 270px;
  height: 100px;
  margin: 20px 16px;
  cursor: pointer;
}
.channel-item-img {
  padding: 10px;
  height: 100px;
}
.channel-item-name {
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.removeLine {
  caret-color: rgba(0, 0, 0, 0);
}
.login-from .el-form-item {
  margin-bottom: 0px !important;
}
.el-form-item__content {
  line-height: initial !important;
}
.custom-body {
}
.class-1 {
  color: #333333;
  background-image: url(../../assets/images/1577692394350.jpg);
  outline: none;
  outline-offset: -1px;
  background-size: cover;
  background-position: center;
  height: 100%;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: center;
  animation-duration: 1s;
  width: 100%;
  background-repeat: no-repeat;
}
.class-2 {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  color: #333333;
  outline: none;
  outline-offset: -1px;
  min-height: 560px;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 1);
  flex-direction: row;
  display: flex;
  animation-duration: 1s;
  border-radius: 12px;
  width: 1000px;
  background-repeat: no-repeat;
}
.class-3 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  height: 100%;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  animation-duration: 1s;
  width: 460px;
  background-repeat: no-repeat;
}
.class-4 {
  /* height: 100%; */
  animation-duration: 1s;
  width: 125%;
}
.class-5 {
  color: #333333;
  outline: none;
  padding-bottom: 20px;
  outline-offset: -1px;
  flex: 1;
  padding-top: 20px;
  height: 100%;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  animation-duration: 1s;
  width: 200px;
  background-repeat: no-repeat;
}
.class-6 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  max-width: 100%;
  flex-direction: column;
  display: flex;
  animation-duration: 1s;
  width: 330px;
  background-repeat: no-repeat;
  margin-top: 20px;
}
.class-7 {
  color: #333333;
  word-wrap: break-word;
  display: inline-block;
  animation-duration: 1s;
  font-size: 36px;
  line-height: 1.4;
  margin-bottom: 20px;
}
.class-8 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  height: 80px;
  max-width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  animation-duration: 1s;
  width: 100%;
  background-repeat: no-repeat;
}
.class-9 {
  color: #bbbbbb;
  word-wrap: break-word;
  display: inline-block;
  animation-duration: 1s;
  font-size: 12px;
  line-height: 1.4;
}
.class-10 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  height: 40px;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  display: flex;
  animation-duration: 1s;
  width: 100%;
  background-repeat: no-repeat;
}
.class-11 {
  color: #333333;
  word-wrap: break-word;
  display: inline-block;
  animation-duration: 1s;
  font-size: 14px;
  line-height: 1.4;
}
.class-12 {
  margin-right: auto;
  animation-duration: 1s;
  background-color: #eee;
  border-radius: 1px;
  width: 100%;
  height: 1px;
  margin-left: auto;
}
.class-13 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  height: 80px;
  max-width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  animation-duration: 1s;
  width: 100%;
  background-repeat: no-repeat;
}
.class-14 {
  color: #bbbbbb;
  word-wrap: break-word;
  padding-bottom: 10px;
  display: inline-block;
  animation-duration: 1s;
  font-size: 14px;
  line-height: 1.4;
}
.class-15 {
  margin-right: auto;
  animation-duration: 1s;
  background-color: #eee;
  border-radius: 1px;
  width: 100%;
  height: 1px;
  margin-left: auto;
}
.class-16 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  height: 80px;
  max-width: 100%;
  align-items: flex-end;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  animation-duration: 1s;
  background-repeat: no-repeat;
}
.class-17 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  flex: 1;
  height: 80px;
  max-width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  animation-duration: 1s;
  width: 200px;
  background-repeat: no-repeat;
}
.class-18 {
  color: #bbbbbb;
  word-wrap: break-word;
  display: inline-block;
  animation-duration: 1s;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 10px;
}
.class-19 {
  margin-right: auto;
  animation-duration: 1s;
  background-color: #eee;
  border-radius: 1px;
  width: 100%;
  height: 1px;
  margin-left: auto;
}
.class-20 {
  cursor: pointer;
  color: #333333;
  margin-right: 10px;
  outline-offset: -1px;
  height: 40px;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  animation-duration: 1s;
  width: 88px;
  background-repeat: no-repeat;
  margin-bottom: 0.85em;
}
.class-21 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  max-width: 100%;
  align-items: flex-end;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  animation-duration: 1s;
  background-repeat: no-repeat;
  margin-bottom: 0.85em;
}
.class-22 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  max-width: 100%;
  flex-direction: column;
  display: flex;
  animation-duration: 1s;
  background-repeat: no-repeat;
}
.class-23 {
  color: #bbbbbb;
  word-wrap: break-word;
  display: inline-block;
  animation-duration: 1s;
  font-size: 12px;
  line-height: 1.4;
}
.class-24 {
  cursor: pointer;
  color: #0099ff;
  word-wrap: break-word;
  display: inline-block;
  animation-duration: 1s;
  font-size: 12px;
  line-height: 1.4;
}
.class-25 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  height: 40px;
  max-width: 100%;
  flex-direction: row;
  display: flex;
  animation-duration: 1s;
  width: 100px;
  background-repeat: no-repeat;
}
.class-26 {
  background-color: #0099ff;
}
.class-27 {
  color: #333333;
  outline: none;
  outline-offset: -1px;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  display: flex;
  animation-duration: 1s;
  width: 100px;
  background-repeat: no-repeat;
  margin-top: 20px;
}
.class-28 {
  color: #333333;
  outline: 1px dashed hsla(0, 0%, 66.7%, 0.7);
  outline-offset: -1px;
  height: 14px;
  max-width: 100%;
  flex-direction: row;
  display: flex;
  animation-duration: 1s;
  width: 14px;
  background-repeat: no-repeat;
}
.class-29 {
  color: #999999;
  word-wrap: break-word;
  display: inline-block;
  margin-left: 10px;
  animation-duration: 1s;
  font-size: 14px;
  line-height: 1.4;
}
@media (max-width: 768px) {
}
.input__label--hoshi::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: calc(100% - 10px);
  border-bottom: 1px solid #b9c1ca;
}
</style>
