/*
 * @Description:
 * @Author: Ray
 * @Date: 2021-12-17 15:06:42
 * @LastEditTime: 2022-07-05 14:29:26
 * @LastEditors: Ray
 */
const ErrorCode = {
  200: {
    500: '发布时间不能为空',
    30008: '验证码错误',
    20011:'管理员账号密码错误'
  },
  400: {
    //参数校验失败
    //参数校验错误
    101: '操作失败',
    102: '没有对应数据',
    103: '无效的参数',
    20030: '客户端权限不能为空',
    10004: '参数校验错误', // 400
  },
  401: {
    //token无效
    9003: '登录已失效,请重新登录',
    9004: '权限错误,请重新登录',
    9005: 'token已失效,请重新登陆',
  },
  403: {
    //权限不足
  },
  404: {
    //资源不存在
  },
  500: {
    //服务端错误
    10001: '系统错误', // 500
  },
}

export default ErrorCode
