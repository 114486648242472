<!--
 * @Description: 
 * @Author: Ray
 * @Date: 2021-12-27 17:13:42
 * @LastEditTime: 2022-07-22 11:36:02
 * @LastEditors: Ray
-->
<template>
  <div id="RoleMangerList" class="ms-index" v-cloak>
    <div class="ms-search" style="padding: 20px 10px 0 10px;">
      <el-row>
        <el-form :model="form" ref="searchForm" label-width="120px" size="mini">
          <el-row>
            <el-col :span="8">
              <el-form-item label="标题" prop="logTitle">
                <el-input
                  v-model="form.logTitle"
                  :disabled="false"
                  :style="{ width: '100%' }"
                  :clearable="true"
                  placeholder="请输入标题"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="请求地址" prop="logUrl">
                <el-input
                  v-model="form.logUrl"
                  :disabled="false"
                  :style="{ width: '100%' }"
                  :clearable="true"
                  placeholder="请输入请求地址"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="请求状态" prop="logStatus">
                <el-select
                  v-model="form.logStatus"
                  :style="{ width: '100%' }"
                  :filterable="false"
                  :disabled="false"
                  :multiple="false"
                  :clearable="true"
                  placeholder="请选择请求状态"
                >
                  <el-option
                    v-for="item in logStatusOptions"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="业务类型" prop="logBusinessType">
                <el-select
                  v-model="form.logBusinessType"
                  :style="{ width: '100%' }"
                  :filterable="false"
                  :disabled="false"
                  :multiple="false"
                  :clearable="true"
                  placeholder="请选择业务类型"
                >
                  <el-option
                    v-for="item in logBusinessTypeOptions"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="操作人员" prop="logUser">
                <el-input
                  v-model="form.logUser"
                  :disabled="false"
                  :style="{ width: '100%' }"
                  :clearable="true"
                  placeholder="请输入操作人员"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!-- <el-form-item label="创建时间" prop="createDate">
                <el-date-picker
                  v-model="form.createDateScope"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  :style="{ width: '100%' }"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                >
                </el-date-picker>
              </el-form-item> -->
            </el-col>
          </el-row>
          <el-row :style="{ padding: '10px' }">
            <el-col :push="16" :span="8" style="text-align: right;">
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="
                  currentPage = 1
                  list()
                "
                >搜索</el-button
              >
              <el-button @click="rest" icon="el-icon-refresh" size="mini"
                >重置</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </div>
    <el-main class="ms-container" style="padding-bottom: 100px;">
      <el-table
        v-loading="loading"
        ref="multipleTable"
        border
        :data="dataList"
        tooltip-effect="dark"
      >
        <template slot="empty">
          {{ emptyText }}
        </template>
        <el-table-column
          label="标题"
          width="180"
          align="center"
          prop="logTitle"
        >
        </el-table-column>
        <el-table-column label="请求地址" align="left" prop="logUrl">
        </el-table-column>
        <el-table-column
          label="请求状态"
          width="80"
          align="center"
          prop="logStatus"
          :formatter="logStatusFormat"
        >
        </el-table-column>
        <el-table-column
          label="操作人员"
          width="140"
          align="center"
          prop="logUser"
        >
        </el-table-column>
        <el-table-column
          label="创建时间"
          width="180"
          align="center"
          prop="createDate"
        >
        </el-table-column>
        <el-table-column label="操作" width="60" align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="save(scope.row.id)"
              >详情</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        class="ms-pagination"
        @current-change="currentChange"
        @size-change="sizeChange"
      >
      </el-pagination>
    </el-main>
  </div>
</template>

<script>
import { queryManagerLogListData } from '@/api/System/log.js'
export default {
  name: 'MangerLogList',
  props: {},
  data() {
    return {
      dataList: [], //系统日志列表
      selectionList: [], //系统日志列表选中
      total: 0, //总记录数量
      pageSize: 10, //页面数量
      currentPage: 1, //初始页
      loadState: false,
      loading: true, //加载状态
      emptyText: '', //提示文字
      logRequestMethodOptions: [
        { value: 'get' },
        { value: 'post' },
        { value: 'put' },
      ],
      logStatusOptions: [
        { value: 'success', label: '成功' },
        { value: 'error', label: '失败' },
      ],
      logBusinessTypeOptions: [
        { value: 'insert', label: '新增' },
        { value: 'delete', label: '删除' },
        { value: 'update', label: '修改' },
        { value: 'other', label: '其他' },
      ],
      logUserTypeOptions: [
        { value: 'other', label: '其他' },
        { value: 'manage', label: '管理员' },
        { value: 'people', label: '会员' },
      ],
      //搜索表单
      form: {
        sqlWhere: null,
        // 标题
        logTitle: null,
        // 请求地址
        logUrl: null,
        // 请求状态
        logStatus: null,
        // 业务类型
        logBusinessType: null,
        // 操作人员
        logUser: null,
        // createDateScope: null,
        // startTime: null,
        // endTime: null,
      },
    }
  },
  watch: {},
  created() {
    this.list()
  },
  methods: {
    //表格数据转换
    logStatusFormat(row, column, cellValue, index) {
      var value = ''
      if (cellValue) {
        var data = this.logStatusOptions.find(function(value) {
          return value.value == cellValue
        })
        if (data && data.label) {
          value = data.label
        }
      }
      return value
    },
    //查询列表
    list() {
      var that = this
      that.loading = true
      that.loadState = false
      var page = {
        pageNo: that.currentPage,
        pageSize: that.pageSize,
      }
      var form = JSON.parse(JSON.stringify(that.form))
      for (let key in form) {
        if (!form[key]) {
          delete form[key]
        }
      }

      //处理时间范围
      // if (form.createDateScope) {
      //   form.startTime = form.createDateScope[0]
      //   form.endTime = form.createDateScope[1]
      // }

      history.replaceState(
        {
          form: form,
          page: page,
          total: that.total,
        },
        ''
      )
      that.loading = true
      queryManagerLogListData(that, Object.assign({}, that.form, page))
        .then(function(data) {
          if (that.loadState) {
            that.loading = false
          } else {
            that.loadState = true
          }
          if (data.data.total <= 0) {
            that.emptyText = '暂无数据'
            that.dataList = []
          } else {
            that.emptyText = ''
            that.total = data.data.total
            that.dataList = data.data.rows
          }
        })
        .catch((err) => {
          that.loading = false
        })
      setTimeout(function() {
        if (that.loadState) {
          that.loading = false
        } else {
          that.loadState = true
        }
      }, 500)
    },
    //新增
    save(id) {
      if (id) {
        this.$router.push({ name: 'MangerLogDetail', query: { id: id } })
      } else {
        this.$router.push({ name: 'MangerLogDetail' })
      }
    },
    //pageSize改变时会触发
    sizeChange(pagesize) {
      this.loading = true
      this.pageSize = pagesize
      this.list()
    },
    //currentPage改变时会触发
    currentChange(currentPage) {
      this.loading = true
      this.currentPage = currentPage
      this.list()
    },
    //重置表单
    rest() {
      this.currentPage = 1
      this.$refs.searchForm.resetFields()
      this.list()
    },
  },
}
</script>

<style scoped></style>
